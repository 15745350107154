import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '@env/environment';
import { AuthAssetGroup, AuthRequest, AuthResponse } from '@app/models/auth.models';
import { keepTrying } from '@app/core';
import { Observable, shareReplay } from 'rxjs';
import { PermissionSetting, User } from '@app/models';
import { map } from 'rxjs/operators';
import { Log } from '@app/helpers/log.helper';
import { LocalStorageService } from '@app/services/local-storage.service';
import {ClientSettingsService} from '@app/services/client-settings.service';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    public onStateUpdated: EventEmitter<void> = new EventEmitter();
    public redirectUrl: string;
    public eulaAcceptedObservable: Observable<boolean>;
    private isAdminObservable: Observable<boolean>;
    private isEditorObservable: Observable<boolean>;

    constructor(private httpClient: HttpClient,
                private localStorage: LocalStorageService,
                private router: Router,
                private clientSettingsService: ClientSettingsService
    ) {
    }

    public authenticateWithServer(authRequestData: AuthRequest): Observable<AuthResponse> {
        return this.httpClient.post<AuthResponse>(this.clientSettingsService.getClientSettings().auth_url, authRequestData).pipe(keepTrying());
    }

    public getIdentityFromServer(): Observable<any> {
        return this.httpClient.get<any>(this.clientSettingsService.getClientSettings().identity_url);
    }

    public checkIsEditor(): Observable<any> {
        if (!this.isEditorObservable) {
            this.isEditorObservable = this.httpClient.get<any>(this.clientSettingsService.getClientSettings().isEditor_url).pipe(
                shareReplay(1)
            );
        }
        return this.isEditorObservable;
    }

    public checkIsAdmin(): Observable<any> {
        if (!this.isAdminObservable) {
            this.isAdminObservable = this.httpClient.get<any>(this.clientSettingsService.getClientSettings().isAdmin_url).pipe(
                shareReplay(1)
            );
        }
        return this.isAdminObservable;
    }

    public checkPermission(assetGroup: AuthAssetGroup|string|null, assetId: number = -1): Observable<PermissionSetting> {
        return this.httpClient
            .post<PermissionSetting>(this.clientSettingsService.getClientSettings().permission_url, {'asset': assetGroup, 'id': assetId})
            .pipe(map(raw => {
                return PermissionSetting.assign(raw);
            }));
    }

    public setTokenInStorage(token: string) {
        this.localStorage.setItem(env.security.storageKeyToken, token);
        this.clearObservables();
        this.onStateUpdated.emit();
    }

    public getTokenFromStorage(): string {
        return this.localStorage.getItem(env.security.storageKeyToken);
    }

    public setIdentityInStorage(identity: string) {
        this.localStorage.setItem(env.security.storageKeyIdentity, identity);
        this.clearObservables();
        this.onStateUpdated.emit();
    }

    public getIdentityFromStorage(): User {
        return this.localStorage.getItem(env.security.storageKeyIdentity);
    }

    public setLoginStateInStorage(loginState: boolean) {
        this.localStorage.setItem(env.security.storageKeyLoginState, loginState);
        this.clearObservables();
        this.onStateUpdated.emit();
    }

    public getLoginStateFromStorage(): boolean {
        return !!this.localStorage.getItem(env.security.storageKeyLoginState);
    }

    public logout(): void {
        this.resetStorage();
        this.router.navigate(['/login']).then();
    }

    public resetStorage(): void {
        this.setLoginStateInStorage(false);
        this.setTokenInStorage(null);
        this.setIdentityInStorage(null);
        this.clearObservables();
        this.onStateUpdated.emit();
    }

    public isAuthenticated(): boolean {
        return !!this.getTokenFromStorage();
    }

    public doGoogleLogin(credential: string, client_id: string): Promise<any> {
        const credentials = { credential: credential, client_id: client_id };
        return new Promise<any>((resolve, reject) => {
            this.httpClient.post(this.clientSettingsService.getClientSettings().google_url,
                JSON.stringify(credentials)
            ).subscribe({
                next: (loginData: any) => {
                    Log.d('AuthenticationService: Did login');

                    if (loginData && loginData.access_token) {
                        Log.d('Current Token:', loginData.access_token);
                        this.setTokenInStorage(loginData.access_token);
                        this.setLoginStateInStorage(true);

                        // We got a result from the login call, but we won't call resolve() yet, as this would return a result
                        // Instead we make the WhoAmI call and then return to the login() call
                        this.getIdentityFromServer().subscribe({
                            next: user => {
                                this.setIdentityInStorage(user);
                                // Now we also have a successful response from the WhoAmI, so we return the loginData
                                resolve(loginData);

                            }, error: (error) => {
                                reject(error);
                            }
                        });
                        return true;
                    } else {
                        return false;
                    }
                }, error: (error) => {
                    reject(error);
                }
            });
        });
    }

    updatePassword(currentPassword: string, newPassword: string): Observable<any> {
        return this.httpClient.patch<any>(this.clientSettingsService.getClientSettings().base_url + '/auth/change_password', JSON.stringify({
            'current': currentPassword,
            'new_password': newPassword
        }));
    }

    public clearObservables(): void {
        this.isAdminObservable = null;
        this.isEditorObservable = null;
        this.eulaAcceptedObservable = null;
    }

    public isLoggedIn(): boolean {
        return !!(this.getTokenFromStorage() && this.getIdentityFromStorage() && this.getLoginStateFromStorage());
    }
}
