import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { AuthService } from '@app/core';
import { Log } from '@app/helpers';
import { Notification, NotificationPing } from '@app/models';
import { CoreService } from '@app/services/core.service';
import { map, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class NotificationService extends CoreService {
    public loadingNotifications = true;
    private notificationSubject: Subject<number> = new Subject<number>();
    private latestCache = new Map<number, Date>();
    private allNotifications: Notification[] = null;

    constructor(protected override auth: AuthService, protected http: HttpClient) {
        super('notification', auth);
        Log.c('Notification Service Ready...');
    }

    getNotifications(): Observable<Notification[]> {
        return this.allNotifications ? of(this.allNotifications)
            : this.http.get<Notification[]>(this.endpoint(), this.options).pipe(
                map(notifications => {
                        const n = notifications.map(Notification.assign);
                        this.allNotifications = n;
                        return n;
                    }
                )
            );
    }

    delete(id: number): Observable<Notification[]> {
        return this.http.delete<Notification[]>(this.endpoint(id), this.options).pipe(
            map(notifications => {
                const n = notifications.map(Notification.assign);
                this.allNotifications = n;
                return n;
            })
        );
    }

    getUnreadNotifications(): Observable<Notification[]> {
        if (this.allNotifications) {
            return of(this.allNotifications.filter(n => !n.is_read));
        }
        return this.http.get<Notification[]>(this.endpoint('unread'), this.options).pipe(
            map(notifications => notifications.map(Notification.assign))
        );
    }

    markAsRead(notificationID: number): Observable<Notification> {
        return this.http.patch<Notification>(this.endpoint(notificationID), { 'is_read': true }, this.options).pipe(
            map(notification => {
                const target = this.allNotifications?.find(n => n.id === notification.id);
                if (target) {
                    target.is_read = true;
                }
                this.notificationSubject.next(notificationID);
                return Notification.assign(notification);
            })
        );
    }

    markAllRead(notificationIDs: number[]): Observable<Notification[]> {
        return this.http.patch<Notification[]>(this.endpoint('bulk_read'), { 'notifications': notificationIDs }, this.options).pipe(
            map(notifications => {
                    notifications = notifications.map(Notification.assign);
                    this.allNotifications = notifications;
                    this.notificationSubject.next(0);
                    return notifications;
                }
            )
        );
    }

    getNotificationSubject(): Observable<number> {
        return this.notificationSubject.asObservable();
    }

    getLatest(): Observable<NotificationPing> {
        return this.http.get<NotificationPing>(this.endpoint('ping'), this.options).pipe(
            tap(n => {
                this.loadingNotifications = true;
                n.notification_date = n.notification_date ? new Date(n.notification_date) : null;
                if (n.notification_date?.getTime() !== this.latestCache.get(n.user_id)?.getTime()) {
                    this.allNotifications = null;
                    this.latestCache.set(n.user_id, n.notification_date);
                    this.notificationSubject.next(0);
                }
            })
        );
    }
}
