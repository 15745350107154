import { User } from '@app/models';

export interface AuthRequest {
    username: string;
    password: string;
}

export interface AuthResponse {
    access_token: string;
}

export class AuthGroup {
    id: number;
    name: string;
    users: User[];
    types: string[];
}

export class AuthGroupIdentity {
    constructor(
        public group_id: number = null,
        public user_id: number = null
    ) {
    }
}

export enum AuthAssetGroup {
    DOCUMENT = 'document',
    ATLAS = 'atlas',
    USER = 'user',
    ADMIN = 'admin'
}
