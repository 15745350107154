<div class="col-lg-6 col-xl-5 col-md-8 mt-md-4 pt-md-5 mx-auto text-center">
    <img class="logo w-50 mx-auto" src="/assets/anthem_badge.png"/>
    @if (!hasFinished) {
        <div class="row">
            <div class="col-lg-10 mt-4 mx-auto" style="max-width: 90%;">
                <p>
                    <small>Please enter your email. A password reset link will be sent to you.</small>
                </p>
                <mat-form-field class="w-75" appearance="outline">
                    <input (keyup.enter)="onRequestPassword()" [formControl]="email" matInput
                           placeholder="Enter your email address" required>
                    <mat-error *ngIf="email.invalid">{{ getErrorMessage() }}</mat-error>
                </mat-form-field>
            </div>
            <div class="col-lg-10 mx-auto">
                <button (click)="onRequestPassword()" [disabled]="email.invalid"
                        [ngClass]="animateOnRouteEnter"
                        color="primary"
                        class="m-2 mat-elevation-z0"
                        mat-flat-button>
                    <span>Request Password Reset</span>
                </button>
                <br>
                <button [routerLink]="['/login']" class="m-2 mat-elevation-z0" mat-stroked-button>Cancel</button>
            </div>
        </div>
    } @else {
        <div class="col-lg-10 mt-4 mb-5 mx-auto">
            <p>
                <small>Your request was sent.<br/>
                    Please check your email for further instructions and check your junk folder if you cannot find the
                    reset email.</small>
            </p>
            <p>
                <small><a [routerLink]="['/login']">Back to Login</a></small>
            </p>
        </div>
    }
</div>
