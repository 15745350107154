import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from '@app/components/pages/root/dashboard/dashboard.component';
import { LoginComponent } from '@app/components/pages/root/login/login.component';
import { ForgottenPasswordComponent } from '@app/components/pages/root/forgotten-password/forgotten-password.component';
import { NoAccessComponent } from '@app/components/pages/root/no-access/no-access.component';
import { SignUpComponent } from '@app/components/pages/root/sign-up/sign-up.component';
import { ResetPasswordComponent } from '@app/components/pages/root/reset-password/reset-password.component';
import { SecurityUsersComponent } from '@app/components/pages/security/users/users.component';
import { AuthGuard } from '@app/core';
import { UserVerificationComponent } from '@app/components/pages/root/user-verification/user-verification.component';
import { SystemEulaComponent } from '@app/components/pages/root/eula/eula.component';

const routes: Routes = [
    { path: 'not-found', component: NoAccessComponent, data: { title: 'Not found', icon: 'fas fa-info-circle' } },
    {
        path: 'dashboard',
        canActivate: [AuthGuard],
        component: DashboardComponent,
        data: { title: 'Dashboard', icon: 'fas fa-info-circle' }
    },
    {
        path: 'login',
        canActivate: [AuthGuard], component: LoginComponent, data: { title: 'Login', icon: 'fas fa-info-circle' }
    },
    {
        path: 'forgotten-password',
        canActivate: [AuthGuard],
        component: ForgottenPasswordComponent,
        data: { title: 'Forgotten Password', icon: 'fas fa-info-circle' }
    },
    {
        path: 'eula',
        canActivate: [AuthGuard],
        component: SystemEulaComponent,
        data: {
            title: 'EULA',
            icon: 'fas fa-book'
        }
    },
    {
        path: 'sign-up',
        canActivate: [AuthGuard], component: SignUpComponent, data: { title: 'Sign-Up', icon: 'fas fa-info-circle' }
    },
    {
        path: 'user/reset',
        canActivate: [AuthGuard], component: ResetPasswordComponent, data: { title: 'Reset Password', icon: 'fas fa-info-circle' }
    },
    {
        path: 'user/invitation',
        canActivate: [AuthGuard], component: SecurityUsersComponent, data: { title: 'Invitation', icon: 'fas fa-info-circle' }
    },
    {
        path: 'user/verification',
        canActivate: [AuthGuard], component: UserVerificationComponent, data: { title: 'Validate', icon: 'fas fa-info-circle' }
    },
    {
        path: '**',
        redirectTo: 'dashboard'
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class RootRoutingModule {
}
