import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment as env } from '@env/environment';
import { AuthService } from '@app/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { LocalStorageService } from '@app/services/local-storage.service';
import { Log } from '@app/helpers';

@Injectable({
    providedIn: 'root'
})
export class JwtInterceptor implements HttpInterceptor {

    constructor(private router: Router,
                private authService: AuthService,
                private localStorageService: LocalStorageService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const requestWithCustomHeaders = request.clone({
            setHeaders: {}
        });
        return next.handle(requestWithCustomHeaders).pipe(
            tap({
                next: (event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {

                        if (event.headers.has('x-new-token')) {
                            this.localStorageService.setItem(env.security.storageKeyToken, event.headers.get('x-new-token'));
                            this.localStorageService.setItem(env.security.storageKeyLoginState, 'true');
                        }

                    }
                },
                error: (err: any) => {
                    let showSessionExpiryMsg = true;
                    if (err instanceof HttpErrorResponse) {
                        if (this.router.url === '/login') {
                            return;
                        }
                        if (this.router.url === '/eula' && err.status === 404) {
                            return;
                        }
                        if (!this.authService.redirectUrl) {
                            this.authService.redirectUrl = this.router.url;
                        }
                        if ((err.status === 404 && !request.url.endsWith('/download')) || err.status === 403) {
                            this.router.navigate(['/not-found']).then();
                        }
                        if (err.status === 401) {
                            const token = this.authService.getTokenFromStorage();
                            if (token) {
                                const decodedToken = jwtDecode<JwtPayload>(token);
                                const d = new Date(decodedToken.exp * 1000); // Convert to milliseconds
                                const timeDiffInMs = Math.abs(d.getTime() - new Date().getTime());
                                const timeDiffInHours = timeDiffInMs / (1000 * 60 * 60);
                                if (timeDiffInHours > 36) {
                                    showSessionExpiryMsg = false;
                                }
                            }
                            // Clear out any data in the storage
                            this.authService.resetStorage();

                            // Redirect to the login route
                            this.router.navigate(['/login'], showSessionExpiryMsg ? {
                                queryParams: { 'session_expired': true }
                            } : {});
                        }
                    }
                },
            })
        );
    }
}
