import { environment as env } from '@env/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { EventEmitter, Injectable } from '@angular/core';
import { AuthService } from '@app/core';
import { NotificationService } from '@app/services/notification.service';
import { Log } from '@app/helpers';
import {ClientSettingsService} from '@app/services/client-settings.service';

@Injectable({
    providedIn: 'root'
})
export class NetworkService {
    public pollingEvent: EventEmitter<boolean> = new EventEmitter();

    public constructor(private notificationService: NotificationService,
                       private authService: AuthService,
                       private http: HttpClient,
                       private clientSettingsService: ClientSettingsService) {
    }

    public getPollingObservable(): Observable<any> {
        const loggedIn = this.authService.isLoggedIn();
        // If we're logged out and not in production, don't ping
        if (!(env.production || loggedIn)) {
            return of(true);
        }
        return loggedIn ? this.notificationService.getLatest() : this.pingServer();
    }

    public startPolling() {
        this.getPollingObservable().subscribe({
            next: () => {
                this.pollingEvent.emit(true);
                setTimeout(() => this.startPolling(), env.httpSettings.pingInterval);
            },
            error: (err: HttpErrorResponse) => {
                Log.d('Failed to ping server.', err);
                setTimeout(() => this.startPolling(), env.httpSettings.pingInterval);
                const pinged = err.url.includes('notification/ping') && err.status !== 0;
                this.pollingEvent.emit(pinged);
                if (pinged) {
                    this.authService.logout();
                }
            }
        });
    }

    private pingServer(): Observable<any> {
        return this.http.get<any>(this.clientSettingsService.getClientSettings().base_url + 'ping');
    }
}
