import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '@app/core';
import { Document, DocumentVersion, RestResponseModel } from '@app/models';
import { environment as env } from '@env/environment';
import { CacheHelper, Log } from '@app/helpers';
import { CacheService } from '@app/services/cache.service';
import { CoreService } from '@app/services/core.service';

@Injectable({
    providedIn: 'root'
})
export class VersionService extends CoreService {

    documentCache: CacheHelper<Document>;


    constructor(protected override auth: AuthService, protected http: HttpClient, private cacheService: CacheService) {
        super('document', auth);
        Log.c('Version Service Ready...');
        this.documentCache = this.cacheService.get<Document>('document');
    }

    static getNextVersionNumber(document: Document): string {
        return env.defaultVersionPrefix.toUpperCase() + (document.versions.length + 1);
    }

    create(documentID: number, version: DocumentVersion): Observable<DocumentVersion> {
        this.documentCache.invalidate(documentID);
        return this.http.post<DocumentVersion>(this.endpoint(`${documentID}/version`), JSON.stringify(version), this.options);
    }

    patch(documentID: number, versionID: number, data: any): Observable<DocumentVersion> {
        this.documentCache.invalidate(documentID);
        return this.http.patch<DocumentVersion>(this.endpoint(`${documentID}/version/${versionID}`),
            JSON.stringify(data), this.options);
    }

    delete(documentID: number, versionID: number): Observable<RestResponseModel> {
        this.documentCache.invalidate(documentID);
        return this.http.delete<RestResponseModel>(this.endpoint(`${documentID}/version/${versionID}`), this.options);
    }
}
