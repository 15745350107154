import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import { SharedModule } from '@app/shared';
import { AuthService, BreadcrumbService, CoreModule } from '@app/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AlertModule } from 'ngx-bootstrap/alert';
import { SignUpComponent } from '@app/components/pages/root/sign-up/sign-up.component';
import { DialogSyncExpiryComponent } from '@app/components/pages/documents/details/dialog-sync-expiry/dialog-sync-expiry.component';
import { UserComponent } from '@app/components/pages/user/user.component';
import {
    ForgottenPasswordComponent,
    LoginComponent,
    NavbarComponent,
    MessagesComponent, RootRoutingModule,
    SearchInputComponent,
    SearchResultsComponent
} from '@app/components/pages/root';
import { NoAccessComponent } from '@app/components/pages/root/no-access/no-access.component';
import { DashboardComponent } from '@app/components/pages/root/dashboard/dashboard.component';
import { ResetPasswordComponent } from '@app/components/pages/root/reset-password/reset-password.component';
import { DateChipPipe } from '@app/pipes/date-chip.pipe';
import { NotificationsComponent } from '@app/components/pages/root/notifications/notifications.component';
import { Log } from '@app/helpers';
import { environment as env } from '@env/environment';
import { ReportComponent } from './components/pages/reporting/report/report.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { TitleCasePipe } from '@app/pipes/title-case.pipe';
import { DocumentSecurityService } from '@app/services/document-security.service';
import { AtlasSecurityService } from '@app/services/atlas-security.service';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { AppSettingsService } from '@app/services/app-settings.service';
import { CacheService } from '@app/services/cache.service';
import { AtlasService } from '@app/services/atlas.service';
import { DocumentService } from '@app/services/document.service';
import { ErrorService } from '@app/services/error.service';
import { ImageService } from '@app/services/image.service';
import { LocalStorageService } from '@app/services/local-storage.service';
import { NotificationService } from '@app/services/notification.service';
import { ScriptService } from '@app/services/script.service';
import { ReviewRequestService } from '@app/services/review-request.service';
import { NoNetworkMessageComponent } from '@app/components/pages/root/no-network-message/no-network-message.component';
import { EulaService } from '@app/services/eula.service';
import { SearchService } from '@app/services/search.service';
import { UserVerificationComponent } from '@app/components/pages/root/user-verification/user-verification.component';
import { SystemEulaComponent } from '@app/components/pages/root/eula/eula.component';
import {ClientSettingsService} from '@app/services/client-settings.service';

/**
 * Factory function to create instance of AppSettingsService and call the setSettings method
 *
 * @param clientSettingsService clientSettingsService object
 */
const appInitializerFn = (clientSettingsService: ClientSettingsService) => {
    return () => {
        return clientSettingsService.setClientSettings();
    };
};

@NgModule({
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        CoreModule,
        SharedModule,
        AppRoutingModule,
        HammerModule,
        AlertModule.forRoot(),
        RootRoutingModule,
        MatButtonToggleModule,
        MatSnackBarModule
    ],
    declarations: [
        AppComponent, SignUpComponent, DialogSyncExpiryComponent, UserComponent, NavbarComponent, LoginComponent,
        NoAccessComponent,
        MessagesComponent,
        SearchInputComponent,
        SearchResultsComponent,
        DashboardComponent,
        ForgottenPasswordComponent,
        ResetPasswordComponent,
        DateChipPipe,
        NotificationsComponent,
        ReportComponent,
        TitleCasePipe,
        NoNetworkMessageComponent,
        UserVerificationComponent,
        SystemEulaComponent
    ],
    providers: [
        CacheService,
        AppSettingsService,
        AtlasSecurityService,
        AtlasService,
        AuthService,
        BreadcrumbService,
        DocumentSecurityService,
        DocumentService,
        ErrorService,
        ClientSettingsService,
        ImageService,
        LocalStorageService,
        NotificationService,
        ScriptService,
        ReviewRequestService,
        NotificationService,
        EulaService,
        SearchService,
        { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3000 } },
        {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 3000}},
        {   /** use the APP_INITIALIZER token to hook into Angular's bootstrapping phase to defer the instantiation of
             * components until the appInitializerFn has completed getting the httpEndpoints
             */
            provide: APP_INITIALIZER,
            useFactory: appInitializerFn,
            multi: true,
            deps: [ClientSettingsService]
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor() {
        Log.setLogging(env.logLevel);
    }
}
