import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@app/core';
import { Log } from '@app/helpers';
import { AuthGroup, RestResponseModel, User } from '@app/models';
import { Observable } from 'rxjs';
import { CoreService } from '@app/services/core.service';
import { map, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class UserService extends CoreService {

    constructor(protected override auth: AuthService, protected http: HttpClient) {
        super('user', auth);
        Log.c('User Service Ready...');
    }

    getAll(): Observable<User[]> {
        return this.http.get<User[]>(this.endpoint(), this.options).pipe(
            map(u => u.map(User.assign))
        );
    }

    get(userID: number): Observable<User> {
        return this.http.get<User>(this.endpoint(userID), this.options).pipe(
            map(User.assign)
        );
    }

    create(user: User): Observable<User> {
        return this.http.post<User>(this.endpoint(), JSON.stringify(user), this.options).pipe(
            map(User.assign)
        );
    }

    update(user: User): Observable<User> {
        return this.http.put<User>(this.endpoint(user.id), JSON.stringify(user), this.options).pipe(
            tap(() => {
                if (user.id === this.auth.getIdentityFromStorage()?.id) {
                    if (user.enabled) {
                        this.auth.getIdentityFromServer().subscribe(u => this.auth.setIdentityInStorage(u));
                    } else {
                        this.auth.logout();
                    }
                }
            }),
            map(User.assign)
        );
    }

    delete(userID: number): Observable<RestResponseModel> {
        return this.http.delete<RestResponseModel>(this.endpoint(userID), this.options).pipe(
            tap(() => {
                if (userID === this.auth.getIdentityFromStorage()?.id) {
                    this.auth.logout();
                }
            })
        );
    }

    toggleState(userID: number, enable: boolean): Observable<User> {
        return this.http.patch<User>(this.endpoint(userID), { enabled: enable }, this.options).pipe(map(User.assign));
    }

    requestPasswordReset(email: string): Observable<RestResponseModel> {
        return this.http.post<any>(this.endpoint('reset'), { email: email }, this.options);
    }

    resetPassword(email: string, new_pass: string, token: string): Observable<RestResponseModel> {
        return this.http.put<any>(this.endpoint('reset'), { email: email, new_pass: new_pass, token: token }, this.options);
    }

    signUp(user: User): Observable<RestResponseModel> {
        return this.http.post<RestResponseModel>(this.endpoint('sign-up'), user, this.options);
    }

    public getUserGroups(userID: number): Observable<AuthGroup[]> {
        return this.http.get<AuthGroup[]>(this.endpoint(`${userID}/groups`), this.options);
    }

    approveUser(userID: number, userGroups: number[]): Observable<User> {
        return this.http.patch<User>(this.endpoint(`approve/${userID}`), { 'groups': userGroups }, this.options).pipe(map(User.assign));
    }
}
