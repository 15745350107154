<mat-nav-list>
    @if (privateAtlases.length) {
        <h3 mat-subheader>Private Atlases</h3>
        <a (click)="navigate(['/atlas', atlas.id])" *ngFor="let atlas of privateAtlases" class="d-none d-md-inline-flex"
           mat-list-item>
            <mat-icon>lock</mat-icon>
            {{ atlas.name }}
        </a>
        <a (click)="navigate(['/atlas/mobile', atlas.id])" *ngFor="let atlas of privateAtlases"
           class="d-inline-flex d-md-none"
           mat-list-item>
            <mat-icon>lock</mat-icon>
            {{ atlas.name }}
        </a>
        <mat-divider></mat-divider>
    }
    @if (publicAtlases.length > 0) {
        <h3 mat-subheader>Public Atlases</h3>
        <a (click)="navigate(['/atlas', atlas.id])" *ngFor="let atlas of publicAtlases" class="d-none d-md-inline-flex"
           mat-list-item>
            <mat-icon class="public-icon">lock_open</mat-icon>
            {{ atlas.name }}
        </a><a (click)="navigate(['/atlas/mobile', atlas.id])" *ngFor="let atlas of publicAtlases"
               class="d-inline-flex d-md-none"
               mat-list-item>
            <mat-icon class="public-icon">lock_open</mat-icon>
            {{ atlas.name }}
        </a>
        <mat-divider></mat-divider>
    }
    <h3 mat-subheader>Other actions</h3>
    <a (click)="navigate(['/atlas'])" class="d-inline-flex" mat-list-item>
        <mat-icon>language</mat-icon>
        Manage Atlases
    </a>
    <a (click)="onDismiss()" class="item-cancel d-inline-flex" mat-list-item>
        Cancel
    </a>
</mat-nav-list>

