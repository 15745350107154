import { Injectable } from '@angular/core';
import { AdvancedSearchRequest, AdvancedSearchResult, Document, SearchResultResponse } from '@app/models';
import { HttpClient } from '@angular/common/http';
import { from, Observable, Subject } from 'rxjs';
import { DocumentService } from '@app/services/document.service';
import {ClientSettingsService} from '@app/services/client-settings.service';
import { Log } from '@app/helpers';

@Injectable({
    providedIn: 'root'
})
export class SearchService {
    private endpointUrl = this.clientSettingsService.getClientSettings().document_url + '/search';
    private readonly searchResults: Subject<SearchResultResponse>;
    private readonly searchObservable: Observable<SearchResultResponse>;

    constructor(public documentService: DocumentService, private httpClient: HttpClient,
                private clientSettingsService: ClientSettingsService) {
        this.searchResults = new Subject<SearchResultResponse>();
        this.searchObservable = from(this.searchResults);
    }

    public getSearchResults(): Observable<SearchResultResponse> {
        return this.searchObservable;
    }

    public search(query: string): void {
        this.documentService.search(query).subscribe((documents: Document[]) => {
            this.searchResults.next({
                count: documents.length,
                categories: [],
                documents: documents
            });
        });
    }

    public advancedSearch(request: AdvancedSearchRequest): Observable<AdvancedSearchResult> {
        Log.d(request);
        return this.httpClient.post<AdvancedSearchResult>(this.endpointUrl, request);
    }
}
