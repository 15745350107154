import { Injectable } from '@angular/core';
import { CacheHelper } from '@app/helpers';

@Injectable({
    providedIn: 'root'
})
export class CacheService {

    caches: Map<string, CacheHelper<any>> = new Map<string, CacheHelper<any>>();

    constructor() {
    }

    get<T>(key: string): CacheHelper<T> {
        if (this.caches.has(key)) {
            return this.caches.get(key);
        } else {
            this.caches.set(key, new CacheHelper<T>());
            return this.caches.get(key);
        }
    }
}
