import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpProgressEvent, HttpRequest, HttpResponse } from '@angular/common/http';
import { AuthService } from '@app/core';
import { CacheHelper, downloadBlob, Log } from '@app/helpers';
import { DocumentFile, RestResponseModel } from '@app/models';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CoreService } from '@app/services/core.service';
import { CacheService } from '@app/services/cache.service';

export class FileUploadStatus {
    public constructor(
        public fileName: string,
        public percentDone: number = 0,
        public bytesUploaded: number = 0) {
    }
}

@Injectable({
    providedIn: 'root'
})
export class FileService extends CoreService {

    documentCache: CacheHelper<Document>;

    constructor(protected override auth: AuthService, protected http: HttpClient, private cacheService: CacheService) {
        super('document', auth);
        Log.c('File Service Ready...');
        this.documentCache = this.cacheService.get<Document>('document');
    }

    patchFileFormat(fileID: number, documentID: number, newFormatID: number, newOrdinal: number): Observable<DocumentFile> {
        this.documentCache.invalidate(documentID);
        return this.http.patch<DocumentFile>(this.endpoint(`${documentID}/file/${fileID}`),
            JSON.stringify({ document_format_id: newFormatID, ordinal: newOrdinal }), this.options).pipe(
                map(DocumentFile.assign)
        );
    }

    delete(documentID: number, fileID: number): Observable<RestResponseModel> {
        this.documentCache.invalidate(documentID);
        return this.http.delete<RestResponseModel>(this.endpoint(`${documentID}/file/${fileID}`), this.options);
    }

    upload(documentID: number, formatID: number, file: File, ordinal: number): Observable<FileUploadStatus> {
        this.documentCache.invalidate(documentID);
        new HttpHeaders().set('Content-Type', 'multipart/form-data');
        const formData = new FormData();
        formData.append('upload', file);
        formData.append('document_format_id', formatID.toString());
        formData.append('ordinal', ordinal.toString());
        return this.http.request(new HttpRequest('POST',
            this.endpoint(`${documentID}/file`), formData, { reportProgress: true })).pipe(
            map(e => this.getEventMessage(e, file)
            )
        );
    }

    download(documentID: number, file: DocumentFile): Observable<any> {
        return this.http.get(this.endpoint(`${documentID}/file/${file.id}/download`), {
            responseType: 'blob', observe: 'response'
        }).pipe(
            tap((response: HttpResponse<Blob>) => {
                downloadBlob(response.body, file.filename);
            })
        );
    }

    /** Return distinct message for sent, upload progress, & response events */
    private getEventMessage(event: HttpEvent<any>, file: File): FileUploadStatus {
        switch (event.type) {
            case HttpEventType.Sent:
                Log.d(`
        Uploading;
        file;
        '${file.name}';
        of;
        size ${file.size}.`);
                break;
            case HttpEventType.UploadProgress:
                // Compute and show the % done:
                const castEvent = (<HttpProgressEvent>event);
                const percentDone = Math.round(100 * castEvent.loaded / castEvent.total);
                Log.d(`;
        File;
        '${file.name}';
        is ${percentDone} % uploaded.`);
                return new FileUploadStatus(file.name, percentDone, castEvent.loaded);
            case HttpEventType.Response:
                Log.d(`;
        File;
        '${file.name}';
        was;
        completely;
        uploaded!`);
                break;
            default:
                Log.d(`;
        File;
        '${file.name}';
        surprising;
        upload;
        event: ${event.type}.
        `);
        }
    }
}
