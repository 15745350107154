import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '@app/core';
import { CacheHelper, Log } from '@app/helpers';
import { Document, RestResponseModel, TransmissionLog } from '@app/models';
import { CoreService } from '@app/services/core.service';
import { CacheService } from '@app/services/cache.service';

@Injectable({
    providedIn: 'root'
})
export class TransmissionService extends CoreService {
    documentCache: CacheHelper<Document>;

    constructor(protected override auth: AuthService, protected http: HttpClient, private cacheService: CacheService) {
        super('document', auth);
        Log.c('Transmission Service Ready...');
        this.documentCache = this.cacheService.get<Document>('document');
    }

    create(documentID: number, transmission: TransmissionLog): Observable<TransmissionLog> {
        this.documentCache.invalidate(documentID);
        return this.http.post<TransmissionLog>(this.endpoint(`${documentID}/transmission`), JSON.stringify(transmission), this.options);
    }

    delete(documentID: number, transmissionID: number): Observable<RestResponseModel> {
        this.documentCache.invalidate(documentID);
        return this.http.delete<RestResponseModel>(this.endpoint(`${documentID}/transmission/${transmissionID}`), this.options);
    }
}
