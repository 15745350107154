<ng-template #results let-navigateToDocument="navigateToDocument" let-searchResults$="searchResults"
             let-value>
    <div class="search-results-overlay-container">
        <div class="scroll-container">
            <mat-list *ngIf="searchResults$ | async as searchResults">
                <mat-list-item class="summary">
                    <p matLine>{{ searchResults.count }} matching results found</p>
                </mat-list-item>
                <mat-list-item *ngIf="searchResults.categories.length > 0" class="header">
                    <p matLine>{{ searchResults.categories.length }} Categories</p>
                </mat-list-item>
                @if (searchResults.categories.length) {
                    <ng-container *ngFor="let categoryResult of searchResults.categories">
                        <mat-list-item>
                            <img alt="..." matListAvatar src="/assets/svg/search/hierarchical-structure.svg">
                            <h3 matLine> {{ categoryResult.title }} </h3>
                            <p matLine>
                                <span> Subject </span>
                                <span class="demo-2"> -- Content </span>
                            </p>
                        </mat-list-item>
                    </ng-container>
                }
                <mat-list-item class="header">
                    <p matLine>{{ searchResults.documents.length }} Documents</p>
                </mat-list-item>
                <ng-container *ngFor="let documentResult of searchResults.documents">
                    <mat-list-item (click)="navigateToDocument(documentResult.id)">
                        <div class="search-results-wrapper">
                            <div>
                                <img alt="..." src="/assets/svg/search/contract.svg" style="width: 40px">
                            </div>
                            <div class="d-flex flex-column">
                                <h3 matLine> {{ documentResult.title }} </h3>
                                <p matLine>
                                    <span> {{ documentResult.description }} </span>
                                </p>
                                <div class="keyword-list" matLine>
                                <span class="keyword" *ngFor="let keyword of documentResult.getKeywords()">
                                    {{ keyword }}
                                </span>
                                </div>
                            </div>
                        </div>
                    </mat-list-item>
                </ng-container>
            </mat-list>
        </div>
    </div>
</ng-template>
