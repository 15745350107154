import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class RoutingStateService {
    private previousUrl: string = null;
    private currentUrl: string = null;

    constructor() {
    }

    public getPreviousUrl(): string {
        return this.previousUrl;
    }

    public getCurrentUrl(): string {
        return this.currentUrl;
    }

    public setPreviousUrl(url: string): void {
        this.previousUrl = url;
    }

    public setCurrentUrl(url: string): void {
        this.currentUrl = url;
    }
}
