import { Component, OnInit, ViewChild } from '@angular/core';
import { AdvancedSearchRequest, AdvancedSearchResult } from '@app/models';
import { SearchService } from '@app/services/search.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { downloadBlob, Log } from '@app/helpers';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'devfu-report',
    templateUrl: './report.component.html',
    styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
    showFilterPanel = true;
    loading = false;
    dataSource = new MatTableDataSource<any>();
    request = new AdvancedSearchRequest();
    results = new AdvancedSearchResult();
    searchForm: FormGroup;


    constructor(private searchService: SearchService,
                private formBuilder: FormBuilder,
                private router: Router,
                private route: ActivatedRoute) {
    }

    @ViewChild(MatPaginator) set matPaginator(paginator: MatPaginator) {
        this.dataSource.paginator = paginator;
    }


    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            this.searchForm = this.formBuilder.group({
                searchCriteria: (params['searchCriteria'] ?? 'all'),
                query: (params['query'] ?? '')
            });
            if (Object.keys(params).length > 0 && !this.loading) {
                this.search();
            }
        });
    }

    search() {
        this.loading = true;
        this.request = { ...this.searchForm.value };
        this.router.navigate([], {
            replaceUrl: true,
            queryParams: { 'searchCriteria': this.request.searchCriteria, 'query': this.request.query }
        });
        this.searchService.advancedSearch(this.request).subscribe({
            next: results => {
                Log.d('Search result', results);
                this.results = results;
                this.dataSource.data = results.data;
                Log.d('Data', this.dataSource.data);
                this.loading = false;
            },
            error: (e) => {
                Log.e(e);
            }
        });
    }

    toggleDisplay() {
        this.showFilterPanel = !this.showFilterPanel;
    }

    downloadCSV() {
        this.downloadFile(this.results);
    }

    downloadFile(data: any) {
        const replacer = (key, value) => (value === null ? '' : value);
        const header = data.columns.map(column => column.charAt(0).toUpperCase() + column.slice(1));
        const csv = data.data.map((row) => {
            return header.map((fieldName, index) => {
                return JSON.stringify(row[index], replacer);
            }).join(',');
        });
        csv.unshift(header.join(','));
        const csvArray = csv.join('\r\n');
        const blob = new Blob([csvArray], { type: 'text/csv' });

        downloadBlob(blob, 'docment_report.csv');
    }

    resetSearchInput() {
        this.searchForm.get('query').reset();
        this.searchForm.get('query').setValue('');
    }
}
