<div class="container-fluid eula-page-container">
    <mat-card appearance="outlined" class="m-md-5">
        <mat-card-title>
            End User Licence Agreement
        </mat-card-title>
        <mat-card-content>
            @if (!eulaError) {
                <p class="accept-label" *ngIf="eula && accepted !== null">
                    @if (!accepted) {
                        Please review and accept the licence terms before using Anthem.
                    }
                </p>
                <div class="eula-container" [innerHTML]="eula"></div>
            } @else {
                <div class="eula-error">There was an error retrieving the current End User Licence Agreement.
                <br/>Please contact an administrator.</div>
            }
        </mat-card-content>
        <mat-card-actions *ngIf="accepted === false && eula">
            <button (click)="decline()" color="warn" mat-stroked-button>Decline</button>
            <button (click)="accept()" color="primary" mat-flat-button>Accept</button>
        </mat-card-actions>
    </mat-card>
</div>
