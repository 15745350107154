export class TagValue {
    [key: string]: any; // Make the model string indexable, eg  w['surname']

    constructor(public tag_id: number = null,
                public abbreviation: string = null,
                public group: string = null,
                public value: string = null
    ) {
    }

    public static assign(o: TagValue) {
        if (typeof o === 'string') {
            return Object.assign(new TagValue(), { value: o });
        } else {
            return Object.assign(new TagValue(), o);
        }
    }
}
