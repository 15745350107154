import { Category, Document } from '@app/models';

export interface SearchResult {
    type: string;
    title: string;
    description: string;
}

export interface SearchResultResponse {
    count: number;
    categories: Category[];
    documents: Document[];
}

export class AdvancedSearchTagFilter {
    tag_id: number;
    value: any;
}

export class AdvancedSearchExpiryFilter {
    delta: number;
    from_date: string;
    to_date: string;
}

export class AdvancedSearchRequest {
    public searchCriteria: string;
    public query: string;
}

export class AdvancedSearchResult {
    public columns: any[];
    public data: any[][];
}
