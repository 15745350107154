import { Component, OnInit } from '@angular/core';
import { EulaService } from '@app/services/eula.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AuthService } from '@app/core';
import { Router } from '@angular/router';
import { MessageService } from '@app/services/message.service';
import { Log } from '@app/helpers';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'devfu-eula',
    templateUrl: './eula.component.html',
    styleUrl: './eula.component.scss'
})
export class SystemEulaComponent implements OnInit {

    eula: SafeHtml;
    accepted = null;
    eulaError = false;

    constructor(
        private eulaService: EulaService, private sanitizer: DomSanitizer, private authService: AuthService,
        private router: Router, private messageService: MessageService) {
    }

    ngOnInit() {
        this.eulaService.checkEulaAccepted(false).subscribe(hasRead => this.accepted = hasRead);
        this.eulaService.getActiveEula().subscribe({
            next: eula => {
                this.eula = this.sanitizer.bypassSecurityTrustHtml(eula.html);
            },
            error: (e: HttpErrorResponse) => {
                Log.d(e);
                this.eulaError = true;
            }
        });
    }

    accept() {
        this.eulaService.acceptEula().subscribe({
            next: () => {
                if (this.authService.redirectUrl && this.authService.redirectUrl !== '/eula') {
                    this.router.navigate([this.authService.redirectUrl]).then();
                } else {
                    this.router.navigate(['/dashboard']).then();
                }
                this.authService.redirectUrl = null;
            },
            error: e => {
                Log.d(e);
                this.messageService.showMessage({
                    'title': 'EULA Error',
                    'message': 'An error occurred while accepting the EULA',
                    duration: 3000,
                    icon: 'fas fa-times-circle'
                });
            }
        });
    }

    decline() {
        this.messageService.showMessage({
            'title': 'EULA Declined',
            'message': 'The terms of the EULA were declined by the user',
            duration: 3000,
            icon: 'fas fa-times-circle'
        });
        this.authService.logout();
    }
}
