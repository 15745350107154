<div class="request-container">
    <div class="mx-auto px-2">
        <mat-card appearance="outlined" *ngIf="notificationState !== LoadingState.Loading">
            <mat-card-title>
                <span>Notifications</span>
                <span class="table-header-wrapper">
                    <mat-slide-toggle class="table-header-button" (change)="showNotifications()"
                                      [(ngModel)]="showDismissed">
                        Show Dismissed
                    </mat-slide-toggle>
                            <button style="margin-left:15px;" mat-flat-button (click)="markAllNotificationsRead()">
                                Dismiss All
                            </button>
                </span>
            </mat-card-title>
            <mat-card-content [hidden]="notificationState != !!LoadingState.LoadedOK && dataSource.data.length">
                <mat-table [dataSource]="dataSource">
                    <ng-container matColumnDef="time">
                        <mat-header-cell *matHeaderCellDef>Time</mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{ element.getCreatedTime().format(dateFormat) }}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="title">
                        <mat-header-cell *matHeaderCellDef>Title</mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <span *ngIf="!element.is_read" class="new-chip">NEW</span>
                            {{ element.title }}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="content">
                        <mat-header-cell *matHeaderCellDef>Content</mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{ element.content }}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="actions">
                        <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <div class="action-button-container">
                                @if (element.is_read) {
                                    <button mat-icon-button (click)="deleteNotification(element.id)"
                                            matTooltip="Delete" matTooltipPosition="left">
                                        <mat-icon class="text-danger">delete</mat-icon>
                                    </button>
                                } @else {
                                    <button mat-icon-button (click)="markNotificationRead(element.id)"
                                            matTooltip="Dismiss" matTooltipPosition="left">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                }
                            </div>
                            <div class="action-button-container">
                                <button mat-icon-button color="primary" matTooltipPosition="right"
                                        (click)="goToNotification(element.target_path)"
                                        *ngIf="(element.target_path ?? '') !== ''" matTooltip="Go">
                                    <mat-icon>arrow_forward_ios</mat-icon>
                                </button>
                            </div>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>
            </mat-card-content>
            @if (notificationState == LoadingState.Loading) {
                <mat-card-content>
                    <div class="large-table-content">
                        <img src="/assets/anthem.png"
                             style="filter: opacity(50%) grayscale(50%); width: 100px; animation: pulse 2s linear infinite;">
                        <span style="font-size: 30px; margin-left: 25px;">Loading notifications...</span>
                    </div>
                </mat-card-content>
            } @else if (notificationState == LoadingState.ErrorLoading) {
                <mat-card-content>
                    <div class="large-table-content">
                        <img src="/assets/svg/sad.svg" style="filter: opacity(30%); width: 100px; ">
                        <span style="font-size: 30px; margin-left: 25px;">Unable to load notifications<br>You may be offline or experiencing internet issues</span>
                    </div>
                </mat-card-content>
            } @else if (notificationState == LoadingState.LoadedNoData || !dataSource.data.length) {
                <mat-card-content>
                    <div class="large-table-content">
                        No notifications
                    </div>
                </mat-card-content>
            }
            <mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons
                           [hidden]="!dataSource?.data?.length"></mat-paginator>
        </mat-card>
    </div>
</div>
