<h3 class="text-uppercase ms-3 d-none d-md-inline-block">
    <a [routerLink]="['dashboard']">Anthem</a>
</h3>
<span class="d-none d-md-inline"> &gt; </span>
<ng-container *ngIf="getItems() | async as items">
    @if (items.length > 1) {
        <ng-container *ngFor="let item of items.slice(0,-1)">
            <a [routerLink]="item.path" class="text-uppercase">
                <strong>{{ item.label }}</strong>
            </a> &gt;
        </ng-container>
    }
    @if (items.length) {
        <span class="current">{{ items.slice(-1)[0].label }}</span>
    } @else {
        <span class="error">Please wait...</span>
    }
</ng-container>
