export class AppSetting {
    constructor(
        public admin_groups: number[] = [],
        public editor_groups: number[] = [],
        public viewer_groups: number[] = [],
        public allow_sign_ups: boolean = false,
        public email_notifications: boolean = false,
        public default_view: boolean = false,
        public default_edit: boolean = false,
        public everyone_creates_documents: boolean = false,
        public everyone_creates_atlases: boolean = false,
        public new_document_count: number = 0,
        public new_expiring_count: number = 0,
        public tag_weights: TagWeights = new TagWeights()
    ) {
    }

    public static assign(a: AppSetting) {
        const n = Object.assign(new AppSetting(), a);
        if (typeof n.tag_weights == 'string') {
            n.tag_weights = JSON.parse(n.tag_weights);
        }
        n.tag_weights = TagWeights.Assign(n.tag_weights);
        return n;
    }

    public isValid(): boolean {
        return true;
    }
}

class TagWeights {
    title_score: number = 0;
    description_score: number = 0;
    keyword_score: number = 0;
    tag_score: number = 0;
    content_score: number = 0;

    public static Assign(o: TagWeights){
        return Object.assign(new TagWeights(), o);
    }

    public normaliseScores(): TagWeights {
        this.title_score ??= 0;
        this.description_score ??= 0;
        this.keyword_score ??= 0;
        this.tag_score ??= 0;
        this.content_score ??= 0;
        return this;
    }
}
