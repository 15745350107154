import { AuthService } from '@app/core';
import {inject} from '@angular/core';
import {ClientSettingsService} from '@app/services/client-settings.service';
import {Log} from '@app/helpers';

export class CoreService {
    protected headers = {
        'Content-Type': 'application/json'
    };

    protected options = { headers: this.headers };

    private clientSettingsService!: ClientSettingsService;

    constructor(protected asset: string, protected auth: AuthService) {
        // use inject from Angular Core so don't have to inject form all the child classes
        this.clientSettingsService = inject(ClientSettingsService);
    }

    protected endpoint(arg: string|Number = ''): string {
        return this.clientSettingsService.getClientSettings().base_url + this.asset + (arg ? '/' + arg.toString() : '');
    }

    protected baseEndpoint(): string {
        return this.clientSettingsService.getClientSettings().base_url;
    }

}
