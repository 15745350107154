export const securitySettingsAssets = [
    {key: 'all', value: '*', label: 'All'},
    {key: 'admin', value: 'admin', label: 'Admin'},
    {key: 'atlas', value: 'atlas', label: 'Atlas'},
    {key: 'document', value: 'document', label: 'Documents'},
    {key: 'user', value: 'user', label: 'User/Groups'}
];

export class PermissionSetting {
    public delete = false;

    constructor(public create = false, public access = false, public modify = false, delete_ = false, public is_admin = false) {
        this.delete = delete_; // reserved keyword work around
    }

    public static assign(a: PermissionSetting) {
        const ps = new PermissionSetting();
        ps.create = !!a.create;
        ps.access = !!a.access;
        ps.modify = !!a.modify;
        ps.delete = !!a.delete;
        ps.is_admin = !!a.is_admin;

        return ps;
    }
}

export interface ISecurityEntryModel {
    id: number;
    security: SecurityEntry[];
    readonly modelType: string;
}

export class SecurityEntry {
    public id: number;
    public atlas_id = 0;
    public category_id = 0;
    public document_id = 0;
    public user_id = 0;
    public group_id = 0;
    public access = false;
    public modify = false;
    public delete = false;

    public static assign(o: SecurityEntry): SecurityEntry {
        return Object.assign(new SecurityEntry(), o);
    }
}
