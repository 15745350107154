import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import {lastValueFrom} from 'rxjs';
import {ClientSettings} from '@app/models/client-settings.model';

@Injectable({
    providedIn: 'root'
})
export class ClientSettingsService {

    private clientSettings!: ClientSettings;
    private httpClient!: HttpClient;

    /**
     * have to use the HttpBackend as the HttpClients handler so the token.interceptor.ts doesn't try to intercept
     * this request and access any of the app settings
     *
     * @param httpBackendHandler Handler that will be passed as the handler for the HttpClient
     */
    constructor(private httpBackendHandler: HttpBackend) {
        this.httpClient = new HttpClient(this.httpBackendHandler);
    }

    /**
     * Read the settings asset app-settings.json which contains settings for the Angular app and store in the
     * appSettings AppSettingsModel variable
     *
     * Allows to have the settings available for runtime configuration rather than build time to use one Docker image
     * and set environment variables for the settings
     *
     * :return: A Promise of appSettings to make sure Angular resolves this promise before continuing with Bootstrapping
     * app.
     */
    async setClientSettings(): Promise<ClientSettings> {
        return await lastValueFrom<ClientSettings>(
            this.httpClient.get<ClientSettings>('assets/app-settings.json'))
            .then(appConfig => {
                    this.clientSettings = ClientSettings.assign(appConfig);
                    return this.clientSettings;
                }
            );
    }

    /**
     * :return: the appSettings variable
     */
    getClientSettings(): ClientSettings {
        return this.clientSettings;
    }
}
