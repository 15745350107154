<div class="my-3 password-reset-container" *ngIf="isValid">
    <mat-card appearance="outlined" class="mx-auto">
        <mat-card-title>Reset Password</mat-card-title>
        <mat-card-content class="text-center">
            <h3 class="m-3">Reset the password for {{email}}</h3>
            <mat-form-field class="d-block mx-5 mt-3" appearance="outline">
                <mat-label>New Password</mat-label>
                <input matInput [(ngModel)]="newPass" (keyup.enter)="onReset()" [type]="hideNew ? 'password' : 'text'">
                <button mat-icon-button matSuffix (click)="hideNew = !hideNew" [attr.aria-label]="'Hide password'"
                        [attr.aria-pressed]="hideNew">
                    <mat-icon>{{hideNew ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
            </mat-form-field>
            <mat-form-field class="d-block mx-5 mb-2" appearance="outline" subscriptSizing="dynamic">
                <mat-label>Confirm Password</mat-label>
                <input matInput [(ngModel)]="confirmPass" (keyup.enter)="onReset()"
                       [type]="hideConfirm ? 'password' : 'text'">
                <button mat-icon-button matSuffix (click)="hideConfirm = !hideConfirm"
                        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideConfirm">
                    <mat-icon>{{hideConfirm ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
            </mat-form-field>
            <button class="my-3 mx-auto mat-elevation-z0 w-25" color="primary"
                    [disabled]="!newPass.length || newPass !== confirmPass" (click)="onReset()" mat-flat-button>
                <span>Reset</span>
            </button>
        </mat-card-content>
    </mat-card>
</div>
