<div class="col-lg-6 col-xl-5 col-md-8 mt-md-4 w-100 text-center sign-up-container">
    <div>
        <div>
            <div class="col-lg-10 mt-2 mx-auto">
                <img class="logo w-50 mx-auto" src="/assets/anthem_badge.png"/>
            </div>
        </div>
        <div>
            <div class="col-lg-10 mt-2 mx-auto">
                Sign-Up for access to AnthemDocs
            </div>
        </div>
        @if (!done) {
            <div>
                <div class="col-10 mx-auto mt-4">
                    Please enter your details to sign up for AnthemDocs. You will have to verify your email address.
                </div>
            </div>
            <form [formGroup]="form">
                <div>
                    <div class="col-lg-10 mt-4 mx-auto">
                        <mat-form-field class="w-75" appearance="outline">
                            <mat-label>Full Name</mat-label>
                            <input formControlName="name" matInput>
                            <mat-error *ngIf="name.invalid">{{ getErrorMessage('name') }}</mat-error>
                        </mat-form-field>
                        <mat-form-field class="w-75" appearance="outline">
                            <mat-label>Email</mat-label>
                            <input formControlName="email" matInput type="email">
                            <mat-error *ngIf="email.invalid">{{ getErrorMessage('email') }}</mat-error>
                        </mat-form-field>
                        <mat-form-field class="w-75" appearance="outline">
                            <mat-label>Password</mat-label>
                            <input formControlName="password" matInput type="password">
                        </mat-form-field>
                        <mat-form-field class="w-75" appearance="outline">
                            <mat-label>Repeat Password</mat-label>
                            <input formControlName="password2" matInput type="password">
                            <mat-error *ngIf="errors?.mismatch">Passwords Don't Match</mat-error>
                        </mat-form-field>
                        <div *ngIf="forceErrors">
                            Passwords don't match
                        </div>
                    </div>
                    <div class="col-lg-10 mx-auto mb-5">
                        <button [disabled]="!loginOk()" (click)="doSignup()" class="m-2 mat-elevation-z0 w-25" color="primary"
                                mat-flat-button>
                            <span *ngIf="isLoading">
                                <i class="fas fa-spinner fa-spin"></i>
                            </span>
                            <span *ngIf="!isLoading">Sign-Up</span>
                        </button>
                        <button [routerLink]="['/login']" class="m-2 mat-elevation-z0 w-25" color="primary"
                                mat-flat-button>
                            <span>Cancel</span>
                        </button>
                    </div>
                </div>
            </form>
        } @else {
            <p class="mt-3">A verification link has been sent to your email address.<br/>
                Please click the link to verify your email address.</p>
            <button [routerLink]="['/login']" class="m-2 mat-elevation-z0 w-25" color="primary" mat-flat-button>
                <span>Return</span>
            </button>
        }
    </div>
</div>
