import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ANIMATE_ON_ROUTE_ENTER } from '@app/core';
import { UserService } from '@app/services/user.service';

@Component({
    selector: 'devfu-forgotten-password',
    templateUrl: './forgotten-password.component.html',
    styleUrls: ['./forgotten-password.component.scss']
})
export class ForgottenPasswordComponent implements OnInit {
    hasFinished = false;
    email = new UntypedFormControl('', [Validators.required, Validators.email]);
    animateOnRouteEnter = ANIMATE_ON_ROUTE_ENTER;

    constructor(private userService: UserService, private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            if (params['email']) {
                this.email.setValue(params['email']);
            }
        });
    }

    onRequestPassword() {
        if (this.email.valid) {
            this.userService.requestPasswordReset(this.email.value).subscribe(() => {
                this.hasFinished = true;
            });
        }
    }

    getErrorMessage() {
        return this.email.hasError('required') ? 'Please enter an email address' :
            this.email.hasError('email') ? 'Not a valid email' :
                '';
    }
}
