import {Router} from '@angular/router';
import {Log} from '@app/helpers/log.helper';

export function joinPath(...pathEntries: any[]): string {
    return pathEntries.filter(p => p !== undefined).map(p => p.toString().replace(/^\/|\/$/g, '')).join('/');
}

export function splitPath(path: string): string[] {
    const pathTokens = path.replace(/(.*)\?.*#.*/g, '$1').split('/');

    if (pathTokens.length && !pathTokens[0]) {
        pathTokens.shift();
    }

    return pathTokens;
}

export function changeHash(url: string, newHash: string): string {
    return url.replace(/#.*/, '') + '#' + newHash;
}

export function buildBack(back: string, defaultData: any, defaultBack: (string|number)[],  fragment = '') {
    // If the back has a second param that's falesy (zero, undefined etc.) remove it to be safe
    if (defaultBack.length === 2 && !defaultBack[1]) {
        defaultBack.pop();
    }

    Log.d('buildBack', back, defaultData, defaultBack, fragment);

    const b = (back && defaultData[back + '_id']) ? [back, defaultData[back + '_id']] : defaultBack;
    const x = fragment ?  { fragment: fragment} : {};

    return [b, x] as const;
}


/**
 * With the given router, navigate back using buildBack() to process the data to work out the appropriate back page
 *
 * @param router
 * @param back
 * @param defaultData
 * @param defaultBack
 * @param fragment
 */
export function navigateBack(router: Router, back: string, defaultData: any,
                             defaultBack: (string|number)[],  fragment = ''): Promise<boolean> {
    return router.navigate(...buildBack(back, defaultData, defaultBack, fragment));
}
