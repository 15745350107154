<ng-container>
    <mat-table [dataSource]="entryDataSource" class="d-table w-100">

        <ng-container matColumnDef="group">
            <mat-header-cell *matHeaderCellDef>Group</mat-header-cell>
            <mat-cell *matCellDef="let s">
                <span *ngIf="s.group_id"><mat-icon>group</mat-icon> {{ s.group_name}}</span>
                <span *ngIf="!s.group_id">-</span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="user">
            <mat-header-cell *matHeaderCellDef>User</mat-header-cell>
            <mat-cell *matCellDef="let s">
                <span *ngIf="s.user_id"><mat-icon>person</mat-icon> {{ s.user_name}}</span>
                <span *ngIf="!s.user_id">-</span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="role">
            <mat-header-cell *matHeaderCellDef>Role</mat-header-cell>
            <mat-cell *matCellDef="let s">{{ s.role }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="reason">
            <mat-header-cell *matHeaderCellDef>Reason</mat-header-cell>
            <mat-cell *matCellDef="let s">{{ s.reason }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef class="w-10"></mat-header-cell>
            <mat-cell *matCellDef="let s" class="w-10">
                <mat-icon matTooltip="Delete" class="del-btn" (click)="deleteEntry(s.id)">delete_forever</mat-icon>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columns" />
        <mat-row *matRowDef="let row; columns: columns;" />
        <tr *matNoDataRow>
            <td class="mat-cell" [attr.colspan]="20">
                <div class="no-data-row">
                    <mat-icon color="accent">info_outline</mat-icon>
                    <div>NO SECURITY ENTRIES DEFINED</div>
                </div>
            </td>
        </tr>
    </mat-table>
</ng-container>
<ng-template #noSecurityEntries>
    <div class="m-4">
        <i>No security entries</i>
    </div>
</ng-template>
