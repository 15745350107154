import { Component, TemplateRef, ViewChild } from '@angular/core';

@Component({
    selector: 'devfu-search-results',
    templateUrl: './search-results.component.html',
    styleUrls: ['./search-results.component.scss'],
    animations: []
})
export class SearchResultsComponent {

    @ViewChild('results', { static: true }) results: TemplateRef<any>;

    constructor() {
    }


}
