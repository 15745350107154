import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Message } from '@app/models';

@Component({
    selector: 'devfu-messages',
    templateUrl: './messages.component.html',
    styleUrls: ['./messages.component.scss']
})
export class MessagesComponent {

    message: Message = null;

    public constructor(
        @Inject(MAT_SNACK_BAR_DATA) public data: Message) {
        this.message = data;
    }

}
