export function convertToMB(bytes: number) {
    return Math.ceil(bytes / 1048576);
}

export function downloadBlob(file: Blob, filename: string): void {
    const fileURL = URL.createObjectURL(file);
    const a = document.createElement('a');
    a.href = fileURL;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(fileURL);
    document.body.removeChild(a);
}
