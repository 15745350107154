import { Injectable } from '@angular/core';
import { CORE_ERRORS } from '@app/core/core.errors';
import { environment as env } from '@env/environment';

@Injectable({
    providedIn: 'root',
})
export class ErrorService {

    static _loadedErrors: { [module: string]: { [key: string]: string } } = null;

    constructor() {
        if (!ErrorService._loadedErrors) {
            ErrorService._loadedErrors = { 'core': CORE_ERRORS };
        }
    }

    public prettifyError(errorKey: any) {
        for (const module in ErrorService._loadedErrors) {
            if (env.enablePrettyErrors) {
                for (const key in ErrorService._loadedErrors[module]) {
                    if (key === errorKey) {
                        if (ErrorService._loadedErrors[module].hasOwnProperty(key)) {
                            return ErrorService._loadedErrors[module][key];
                        }
                    }
                }
            }
        }
        return 'An error occurred';
    }
}
