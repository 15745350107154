@if (isFullscreen()) {
    <div class="has-fullscreen">
        <router-outlet #o="outlet"></router-outlet>
    </div>
} @else {
    <mat-sidenav-container>
        <mat-sidenav #sidenav mode="push">
            @if (window.innerWidth <= 992) {
                <div class="branding">
                    <!--<img [src]="logoSideBar"/>-->
                    <img src="assets/anthem_logo.png">
                    <span>Anthem</span>
                </div>
                <mat-nav-list>
                    @if (identity) {
                        <a (click)="sidenav.close()" class="mb-4 identity-item" mat-list-item routerLink="/user">
                            <div>{{ identity?.name }}<br/>
                                <small>{{ identity?.email }}</small>
                            </div>
                        </a>
                        <mat-divider></mat-divider>
                    }
                    @for (item of navigation; track item.link) {
                        <a (click)="sidenav.close()" *ngIf="!item.admin || isAdmin" [routerLink]="[item.link]"
                           mat-list-item routerLinkActive="active">
                            <i [ngClass]="item.icon + ' w-25'"></i> {{ item.label }}
                        </a>
                    }
                    <a (click)="onLogout()" *ngIf="identity" mat-list-item>
                        <i class="fas fa-lock w-25"></i> Logout
                    </a>
                </mat-nav-list>
                <div class="row">
                    <div class="col-12 signature clearfix">
                        <a class="d-block" routerLink="/eula" (click)="sidenav.close()" *ngIf="isAuthenticated">EULA</a>
                        &#169; {{ year }} DevFU Pty. Ltd.
                        <div class="float-right version">v{{ version }}</div>
                    </div>
                </div>
            }
        </mat-sidenav>

        <div class="wrapper">
            <devfu-navbar [ngClass]="{'d-none d-sm-none d-xs-none d-md-block dl-block d-xl-block': !isAuthenticated}"
                          [sidenav]="sidenav"
                          class="fixed-top"></devfu-navbar>

            <div class="content">
                <router-outlet #o="outlet"></router-outlet>
            </div>
            <div class="footer fixed-bottom d-none d-lg-block">
                <div class="row">
                    <div class="col-12 signature">
                        &#169; {{ year }} - DevFU Pty. Ltd.
                        <i class="fas fa-rocket"></i> {{ version }}
                        <span *ngIf="!isProd">[{{ envName }}]</span>
                        <a routerLink="/eula" *ngIf="isAuthenticated">EULA</a>
                    </div>
                </div>
            </div>
        </div>
    </mat-sidenav-container>
}
