/*
Class with root members to use instead of Log.d as that breaks in IE. Later on we can use this to turn off
anything other than errors from being displayed in the console if necessary.

You can use either the short (eg. w()) form or the long form (eg. warn()) depending on your style.

e()/ error(): Errors, something didn't work properly
w()/ warn(): Warnings, something might not be working correctly
i()/ info(): Information that might be useful about what is happening
c()/ construct(): Construction information about objects. Use when saying "MagicService created"
d()/ debug(): Debugging information. This is detailed information that is likely only useful when debugging

We use | (bitwise OR) to combine LogLevel flags and & (bitwise AND) to check if they are set before dumping text
to the console itself. So, if you want to change the log level in app.module.ts you'd call:

Log.setLogging(LogLevel.Error | LogLevel.Warning); // Errors and warnings shown

or

Log.disable();  // Unsets all logging
Log.setLevel(LogLevel.Error);
Log.setLevel(LogLevel.Warning);

 */

// Disable no-bitwise for this file
/* eslint no-bitwise: 0 */

export enum LogLevel {
    None = 0,
    Error = 1 << 0,
    Warning = 1 << 1,
    Info = 1 << 2,
    Construct = 1 << 3,
    Debug = 1 << 4
}

export class Log {

    // By default, show all log info.
    private static logLevel: number = LogLevel.Error | LogLevel.Warning | LogLevel.Info | LogLevel.Construct | LogLevel.Debug;

    public static disable() {
        this.logLevel = LogLevel.None;
    }

    /* Set the logging level to this and only this. Flags combined with | operator */
    public static setLogging(newLogLevel: number) {
        this.logLevel = newLogLevel;
    }

    /* Remove a single level of logging, preserving existing logging settings */
    public static clearLevel(level: LogLevel) {
        this.logLevel &= ~level;
    }

    /* Add a level of logging, preserving existing logging settings */
    public static setLevel(level: LogLevel) {
        this.logLevel |= level;
    }

    public static e(...args: any[]): void {
        Log.error(...args);
    }

    public static error(...args: any[]): void {
        const enabled = this.logLevel & LogLevel.Error;
        if (console && enabled) {
            console.error(...args);
        }
    }

    public static w(...args: any[]): void {
        Log.warn(...args);
    }

    public static warn(...args: any[]): void {
        if (console && (this.logLevel & LogLevel.Warning)) {
            console.warn(...args);
        }
    }

    public static i(...args: any[]): void {
        Log.info(...args);
    }

    public static info(...args: any[]): void {
        if (console && (this.logLevel & LogLevel.Info)) {
            console.log(...args);
        }
    }

    public static c(...args: any[]): void {
        Log.construct(...args);
    }

    public static construct(...args: any[]): void {
        if (console && (this.logLevel & LogLevel.Construct)) {
            console.log(...args);
        }
    }

    public static d(...args: any[]): void {
        Log.debug(...args);
    }

    public static debug(...args: any[]): void {
        // We use console.log rather than console.debug as chrome is weird with debug()
        if (console && (this.logLevel & LogLevel.Debug)) {
            console.log(...args);
        }
    }

    /* tslint:enable:no-bitwise */
}


