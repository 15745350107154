<div class="row">
    <div class="col-2 pt-2">
        <i class="icon fas fa-exclamation-triangle fa-2x text-danger"></i>
    </div>
    <div class="col-10">
        <p class="m-0 pl-2 title"><strong>Connection lost</strong></p>
        <p class="m-0 pl-2 message">The connection to the Anthem server has been interrupted. Please check your
            connection settings.</p>
    </div>
</div>
