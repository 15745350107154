export class ClientSettings {
    constructor(
        public base_url: string = '',
        public auth_url: string = base_url + 'auth/login',
        public google_url: string = base_url + 'auth/google',
        public identity_url: string = base_url + 'auth/whoami',
        public isAdmin_url: string = base_url + 'auth/is_admin',
        public isEditor_url: string = base_url + 'auth/is_editor',
        public permission_url: string = base_url + 'auth/permission',
        public user_url: string = base_url + 'user',
        public group_url: string = base_url + 'group',
        public atlas_url: string = base_url + 'atlas',
        public document_url: string = base_url + 'document',
        public tag_url: string = base_url + 'tag',
        public securitySettings_url: string = base_url + 'security_setting',
        public securityEntries_url: string = base_url + 'security',
        public settings_url: string = base_url + 'settings',
        public googleClientID: string = '',
        public googleLoginEnabled: boolean = false) {
    }

    public static assign(o: ClientSettings) {
        return Object.assign(new ClientSettings(o.base_url), o);
    }
}
