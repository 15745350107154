import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

type KeyType = number|string;

export class CacheHelper<T> {
    contents: { [key: KeyType]: T } = {};
    contentAge: { [key: KeyType]: number } = {};

    contentsCollection: T[] = [];
    contentsCollectionAge: number = 0;

    constructor(protected maxAgeSecs: number = 60) {
        this.maxAgeSecs = maxAgeSecs * 1000;
    }

    /**
     * Capture an entity o
     * @param key
     */
    capture(key: KeyType) {
        return (x: T) => {
            this.contents[key] = x;
            this.contentAge[key] = Date.now();
        };
    }

    captureAll() {
        return (x: T[]) => {
            this.contentsCollection = x;
            this.contentsCollectionAge = Date.now();
        };
    }

    invalidate(key: KeyType) {
        this.invalidateCollection();

        delete this.contents[key];
        delete this.contentAge[key];
    }

    invalidateAll() {
        this.invalidateCollection();

        this.contents = {};
        this.contentAge = {};
    }

    invalidateCollection() {
        this.contentsCollection = [];
        this.contentsCollectionAge = 0;
    }

    has(key: KeyType): boolean {
        return this.contentAge[key] > 0 && this.contentAge[key] + this.maxAgeSecs > Date.now();
    }

    hasAll(): boolean {
        return this.contentsCollectionAge > 0 && this.contentsCollectionAge + this.maxAgeSecs > Date.now();
    }

    return(key: KeyType, func: Observable<T>): Observable<T> {
        return this.has(key) ? of(this.contents[key]) : func.pipe(tap(this.capture(key)));
    }

    returnAll(func: Observable<T[]>): Observable<T[]> {
        return this.hasAll() ? of(this.contentsCollection) : func.pipe(tap(this.captureAll()));
    }
}
