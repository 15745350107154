import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SecurityTokenService } from '@app/services/security-token.service';
import { Log } from '@app/helpers';
import { MessageService } from '@app/services/message.service';
import { AuthService } from '@app/core';

@Component({
    selector: 'devfu-user-verification',
    templateUrl: './user-verification.component.html',
    styleUrls: ['./user-verification.component.scss']
})
export class UserVerificationComponent implements OnInit {

    isValid = false;
    email: string;
    token: string;

    constructor(private route: ActivatedRoute,
                private tokenService: SecurityTokenService,
                private authService: AuthService,
                private messageService: MessageService) {
    }

    ngOnInit() {
        this.email = this.route.snapshot.queryParamMap.get('email');
        this.token = this.route.snapshot.queryParamMap.get('token');
        this.isSessionValid();
    }


    isSessionValid() {
        this.tokenService.checkEmailVerificationToken(this.email, this.token).subscribe({
            next: () => {
                Log.d('Security token is valid');
                this.isValid = true;
            },
            error: () => {
                this.isValid = false;
                this.messageService.showMessage({
                    'title': 'Invalid token',
                    'message': 'The provided token is invalid',
                    duration: 3000,
                    icon: 'fas fa-times-circle'
                });
                Log.d('Security token not valid');
                this.authService.logout();
            }
        });
    }
}
