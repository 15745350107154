import { environment as env } from '@env/environment';
import { Moment } from 'moment-timezone';
import * as moment from 'moment-timezone';

export class User {
    public constructor(
        public id: number = 0,
        public name: string = '',
        public email: string = '',
        public enabled: boolean = false,
        public remote_system_identity: string = '',
        public password: string = '',
        public tz: string = '',
        public token: string = '',
        public is_pending: boolean = false,
        public sign_up_ip: string = '',
        public verified: boolean = false,
        public groups?: number[]
    ) {
    }

    public static assign(o: any) {
        return Object.assign(new User(), o);
    }
}

export class UserInvitation {
    public constructor(
        public id: number = 0,
        public name: string = '',
        public email: string = '',
        public inviter_id: number = 0,
        public ctime: Date = null,
        public groups: number[] = [],

        public groupNames?: string[],
        public inviter?: User
    ) {
    }

    public static assign(o: any): UserInvitation {
        return Object.assign(new UserInvitation(), o);
    }

    public getCreatedTime(): Moment {
        return moment(this.ctime);
    }
}

