import { Component, OnInit } from '@angular/core';
import { Atlas } from '@app/models';
import { AtlasService } from '@app/services/atlas.service';
import { Router } from '@angular/router';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
    selector: 'devfu-atlas-menu',
    templateUrl: './atlas-menu.component.html',
    styleUrls: ['./atlas-menu.component.scss']
})
export class AtlasMenuComponent implements OnInit {

    privateAtlases: Atlas[] = [];
    publicAtlases: Atlas[] = [];

    constructor(
        private bottomSheetRef: MatBottomSheetRef<AtlasMenuComponent>,
        private router: Router,
        private atlasService: AtlasService) {
    }

    ngOnInit() {
        this.atlasService.getAll().subscribe((atlases: Atlas[]) => {
            this.privateAtlases = atlases.filter(a => a.is_private);
            this.publicAtlases = atlases.filter(a => !a.is_private);
        });
    }

    navigate(commands: any[]) {
        this.router.navigate(commands).then(() => {
            this.bottomSheetRef.dismiss();
        });
    }

    onDismiss() {
        this.bottomSheetRef.dismiss();
    }

}
