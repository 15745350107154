import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export class DialogConfirmData {
    public constructor(public message: string,
                       public title?: string) {
    }
}

@Component({
    selector: 'devfu-dialog-confirm',
    templateUrl: './dialog-confirm.component.html',
    styleUrls: ['./dialog-confirm.component.scss']
})
export class DialogConfirmComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<DialogConfirmComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogConfirmData) {
    }

    ngOnInit() {
    }

}
