import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@app/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { environment as env } from '@env/environment';
import { Router } from '@angular/router';
import { AtlasMenuComponent } from '@app/components/widgets/atlas-menu/atlas-menu.component';
import { Notification, User } from '@app/models';
import { Subscription } from 'rxjs';
import { MessageService } from '@app/services/message.service';
import { NotificationService } from '@app/services/notification.service';
import { EulaService } from '@app/services/eula.service';

@Component({
    selector: 'devfu-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {
    @Input() sidenav = null;
    logo = '';
    isAuthenticated = false;
    identity: User = null;
    navigation = env.navigation;
    isAdmin = false;
    mobileSearchVisible = false;
    dateFormat = env.defaultDateFormat;

    protected showNotifications = false;
    protected notifications: Notification[] = [];
    protected displayedNotifications: Notification[] = [];
    protected unreadTotal = 0;

    private isLoadingNotifications = false;
    private notificationSubscription: Subscription;
    private authSubscription: Subscription;


    constructor(private authService: AuthService,
                private messageService: MessageService,
                private notificationService: NotificationService,
                private bottomSheet: MatBottomSheet,
                private router: Router,
                private eulaService: EulaService) {
        this.notificationSubscription = this.notificationService.getNotificationSubject().subscribe(() => {
            if (!this.isLoadingNotifications) {
                this.getNotifications();
            }
        });
    }

    ngOnInit() {
        let eulaObservable: Subscription;
        const handleAuth = () => {
            this.identity = this.authService.getIdentityFromStorage();
            const loggedIn = this.authService.getLoginStateFromStorage();
            if (this.identity && loggedIn) {
                eulaObservable = this.eulaService.checkEulaAccepted().subscribe(hasReadEula => {
                    this.isAuthenticated = hasReadEula;
                    if (this.isAuthenticated) {
                        this.authService.checkIsAdmin().subscribe(isAdmin => this.isAdmin = isAdmin);
                        if (!this.isLoadingNotifications) {
                            this.getNotifications();
                        }
                    }
                });
            } else {
                eulaObservable?.unsubscribe();
                this.isAuthenticated = false;
                this.identity = null;
            }
        };

        this.authSubscription = this.authService.onStateUpdated.subscribe(handleAuth);
        handleAuth();
    }

    ngOnDestroy() {
        this.notificationSubscription.unsubscribe();
        this.authSubscription.unsubscribe();
    }

    getNotifications(): void {
        this.isLoadingNotifications = true;
        this.notificationService.getUnreadNotifications().subscribe(n => {
            this.unreadTotal = n.length;
            this.notifications = n.sort((a, b) => b.ctime.getTime() - a.ctime.getTime());
            this.getDisplayedNotifications();
            this.isLoadingNotifications = false;
        });
    }

    getDisplayedNotifications(): void {
        this.displayedNotifications = this.notifications.slice(0, 8);
    }

    onUserClick() {
        this.router.navigate(['/user']);
    }

    onLogoutClick() {
        this.messageService.showMessage({
            'title': 'Security',
            'message': 'Logout successful',
            duration: 3000,
            icon: 'fas fa-shield-alt'
        });

        this.authService.logout();
    }

    openAdvancedSearch() {
        this.mobileSearchVisible = false;
        this.router.navigate(['/search']);
    }

    openAtlasMenu() {
        const bottomSheetRef = this.bottomSheet.open(AtlasMenuComponent);
    }

    markNotificationRead(event: MouseEvent, notificationID: number): void {
        if (event) {
            event.stopPropagation();
        }
        this.isLoadingNotifications = true;
        this.notifications = this.notifications.filter(n => n.id !== notificationID);
        this.unreadTotal = this.notifications.length;
        this.notificationService.markAsRead(notificationID).subscribe(() => {
            this.unreadTotal -= 1;
            this.notifications = this.notifications.filter(n => n.id !== notificationID);
            this.getDisplayedNotifications();
            this.isLoadingNotifications = false;
        });
    }

    markAllNotificationsRead(): void {
        this.isLoadingNotifications = true;
        const notificationIDS = this.notifications.map(n => n.id);
        this.notifications = [];
        this.unreadTotal = 0;
        this.notificationService.markAllRead(notificationIDS).subscribe(notifs => {
            this.unreadTotal = notifs.length; // This should be 0, but check in case a notification came in during the request
            this.notifications = notifs.filter(n => !n.is_read).sort((a, b) => b.ctime.getTime() - a.ctime.getTime());
            this.getDisplayedNotifications();
            this.isLoadingNotifications = false;
        });
    }

    goToNotification(notificationID: number, targetPath: string): void {
        if ((targetPath ?? '') === '') {
            return;
        }
        this.showNotifications = false;
        this.router.navigateByUrl(targetPath).then();
    }

    viewAllNotifications(): void {
        this.showNotifications = false;
        this.router.navigateByUrl('/notifications');
    }

    hasPinged(): boolean {
        return this.notificationService.loadingNotifications;
    }
}
