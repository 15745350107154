import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from '@app/core';

@Component({
    selector: 'devfu-no-access',
    templateUrl: './no-access.component.html',
    styleUrls: ['./no-access.component.scss']
})
export class NoAccessComponent implements OnInit {

    constructor(private breadcrumbService: BreadcrumbService) {
    }

    ngOnInit() {
        this.breadcrumbService.setCurrentPath([
            { label: 'Resource not found', path: null }
        ]);

    }

}
