import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CoreService } from '@app/services/core.service';
import { AuthService } from '@app/core';
import { CacheHelper, Log } from '@app/helpers';
import { Document, ReviewRequest } from '@app/models';
import {RestResponseModel} from '@app/models/rest-response.model';
import {map} from 'rxjs/operators';
import {DocumentAtlas} from '@app/models/document-atlas.model';
import {CacheService} from '@app/services/cache.service';
import {joinPath} from '@app/helpers/path.helper';

@Injectable({
    providedIn: 'root'
})
export class DocumentService extends CoreService {

    cache: CacheHelper<Document>;

    constructor(protected override auth: AuthService, protected http: HttpClient, private cacheService: CacheService) {
        super('document', auth);
        Log.c('Document Service Ready...');
        this.cache = this.cacheService.get<Document>('document');
    }

    get(documentID: number): Observable<Document> {
        return this.cache.return(documentID,
            this.http.get<Document>(this.endpoint(documentID), this.options).pipe(
                map(d => Document.assign(d))
            )
        );
    }

    getDocumentViewers(documentID: number): Observable<number[]> {
        this.cache.invalidate(documentID);
        return this.http.get<number[]>(this.endpoint(`${documentID}/viewer`), this.options);
    }

    getAllExpiring(): Observable<Document[]> {
        return this.http.get<Document[]>(this.endpoint('expiring/all'), this.options).pipe(
            map(docs => docs.map(Document.assign))
        );
    }

    create(document: Document): Observable<Document> {
        this.cache.invalidateCollection();
        return this.http.post<Document>(this.endpoint(), JSON.stringify(document), this.options).pipe(
            map(d => Document.assign(d))
        );
    }

    update(document: Document): Observable<Document> {
        this.cache.invalidate(document.id);
        Log.d('Service document', document);
        return this.cache.return(document.id,
            this.http.put<Document>(this.endpoint(document.id), JSON.stringify(document), this.options).pipe(
                map(d => Document.assign(d))
            ));
    }

    patch(documentID: number, data: any): Observable<Document> {
        this.cache.invalidate(documentID);
        return this.cache.return(documentID,
            this.http.patch<Document>(this.endpoint(`${documentID}`), data, this.options).pipe(
                map(d => Document.assign(d))
            )
        );
    }

    delete(documentID: number): Observable<RestResponseModel> {
        this.cache.invalidate(documentID);
        return this.http.delete<RestResponseModel>(this.endpoint(documentID), this.options);
    }

    search(query: string): Observable<Document[]> {
        return this.http.get<Document[]>(this.endpoint(`search/${query}`), this.options).pipe(
            map(docs => docs.map(d => Document.assign(d)))
        );
    }

    getExpiring(): Observable<Document[]> {
        return this.http.get<Document[]>(this.endpoint('expiring'), this.options).pipe(
            map(docs => docs.map(d => Document.assign(d)))
        );
    }

    getLatest(): Observable<Document[]> {
        return this.http.get<Document[]>(this.endpoint('new'), this.options).pipe(
            map(docs => docs.map(d => Document.assign(d)))
        );
    }

    getUserDocuments(userID: number): Observable<Document[]> {
        return this.http.get<Document[]>(joinPath(this.baseEndpoint(), 'user', userID, 'documents'), this.options).pipe(
            map(docs => docs.map(d => Document.assign(d)))
        );
    }

    getDocumentAtlas(document_id: number): Observable<DocumentAtlas[]> {
        return this.http.get<DocumentAtlas[]>(this.endpoint(`${document_id}/atlas/path`), this.options);
    }

    getDocumentReviewRequests(documentID: number): Observable<ReviewRequest[]> {
        return this.http.get<ReviewRequest[]>(this.endpoint(`${documentID}/review_request`), this.options).pipe(
            map(reviews => reviews.map(ReviewRequest.assign))
        );
    }
}
