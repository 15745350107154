import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '@app/core';
import { AppSetting, AuthGroup, Document, UserSetting } from '@app/models';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Log } from '@app/helpers';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { environment as env } from '@env/environment';
import { DocumentService } from '@app/services/document.service';
import { UserService } from '@app/services/user.service';
import { UserSettingService } from '@app/services/user-setting.service';
import { MessageService } from '@app/services/message.service';
import { AppSettingsService } from '@app/services/app-settings.service';


@Component({
    selector: 'devfu-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
    @ViewChild(MatSort) sort: MatSort = new MatSort();

    isChangePassword = false;
    currentPassword = '';
    password = '';
    passwordRepeat = '';
    passwordError = '';
    identity = null;
    documentsDataSource: MatTableDataSource<Document> = new MatTableDataSource<Document>([]);
    displayedColumns: string[] = ['title', 'ctime', 'expiry'];
    dateFormat = env.defaultDateFormat;
    revisionDateFormat = env.revisionDateFormat;
    groups: AuthGroup[];
    searchKeyword = '';

    allSettings: UserSetting[] = [];
    appSettings: AppSetting;
    settingEmailNotifications = true;

    constructor(private authService: AuthService,
                private documentService: DocumentService,
                private userService: UserService,
                private userSettingService: UserSettingService,
                private messageService: MessageService,
                private router: Router,
                private settingsService: AppSettingsService) {
    }

    async ngOnInit(): Promise<void> {
        this.identity = await lastValueFrom(this.authService.getIdentityFromServer());
        this.loadData();
    }

    cancel() {
        this.isChangePassword = false;
        this.currentPassword = '';
        this.password = '';
        this.passwordRepeat = '';
        this.passwordError = '';
    }

    loadData() {
        this.settingsService.getAll().subscribe(s => {
            this.appSettings = s;
        });
        // load documents belong to this user
        this.documentService.getUserDocuments(this.identity.id)
            .subscribe({
                next: (documents: Document[]) => {
                    // Log.d(documents);
                    this.documentsDataSource.data = documents;
                },
                error: error => {
                    Log.d(error);
                },
                complete: () => {
                    this.documentsDataSource.sort = this.sort;
                }
            });

        this.userService.getUserGroups(this.identity.id)
            .subscribe({
                next: (groups) => {
                    // Log.d(groups);
                    this.groups = groups;
                },
                error: error => {
                    Log.d(error);
                }
            });

        this.userSettingService.getAll(false).subscribe(settings => {
            this.setUserSettings(settings);
        });
    }

    onSave() {
        // if password fields are empty
        if (!this.isPasswordValid()) {
            return;
        }

        this.authService.updatePassword(this.currentPassword, this.password)
            .subscribe({
                next: () => {
                    this.messageService.showMessage({
                        'title': 'Change password',
                        'message': 'Password updated successfully',
                        duration: 3000,
                        icon: 'fas fa-check-circle'
                    });
                    // reset
                    this.cancel();
                },
                error: err => {
                    this.messageService.showMessage({
                        'title': 'Change password',
                        'message': 'Password update failed. ' + err.error,
                        duration: 3000,
                        icon: 'fas fa-exclamation-circle'
                    });
                },
            });
    }

    viewCurrentDocument(document_id) {
        this.router.navigate(['/document/' + document_id]);
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.documentsDataSource.filter = filterValue.trim().toLowerCase();
    }

    cancelSearch() {
        this.searchKeyword = '';
        this.documentsDataSource.filter = null;
    }

    isPasswordValid(): boolean {
        return this.password === this.passwordRepeat && this.password !== '';
    }

    updateError(): void {
        if (this.password !== this.passwordRepeat) {
            this.passwordError = 'New passwords do not match';
        } else if (this.password === '') {
            this.passwordError = 'New password cannot be blank';
        } else {
            this.passwordError = '';
        }
    }

    setUserSettings(settings: UserSetting[]): void {
        this.allSettings = settings;
        this.settingEmailNotifications = (settings.find(s => s.key === 'allow_email_notifications')?.int_value ?? 1) !== 0;
    }

    saveUserSettings(): void {
        const newConfig: UserSetting[] = [
            new UserSetting('allow_email_notifications', (+this.settingEmailNotifications).toString(), +this.settingEmailNotifications)
        ];

        this.userSettingService.updateSettings(newConfig).subscribe({
            next: settings => {
                this.setUserSettings(settings);
                this.messageService.showMessage({
                    'title': 'Settings saved',
                    'message': 'User settings updated successfully',
                    duration: 3000,
                    icon: 'fas fa-check-circle'
                });
                // reset
                this.cancel();
            },
            error: () => {
                this.messageService.showMessage({
                    'title': 'Saving failed',
                    'message': 'Failed to update user settings.',
                    duration: 3000,
                    icon: 'fas fa-exclamation-circle'
                });
            }
        });
    }

}
