import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AuthService } from '@app/core';
import { Log } from '@app/helpers';
import { AppSetting } from '@app/models';
import { CoreService } from '@app/services/core.service';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AppSettingsService extends CoreService {

    appSettings: AppSetting = null;

    constructor(protected override auth: AuthService, protected http: HttpClient) {
        super('settings', auth);
        Log.c('App Settings Service Ready...');
    }

    getAll(useCache: boolean = true): Observable<AppSetting> {
        return this.appSettings && useCache ? of(this.appSettings) : this.http.get<AppSetting>(this.endpoint(), this.options).pipe(
            map(s => {
                const settings = AppSetting.assign(s);
                this.appSettings = settings;
                return settings;
            })
        );
    }

    patch(data: any): Observable<AppSetting> {
        return this.http.patch<AppSetting>(this.endpoint(), data, this.options).pipe(
            map(s => {
                const settings = AppSetting.assign(s);
                this.appSettings = settings;
                return settings;
            })
        );
    }
}
