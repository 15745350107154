import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as env } from '@env/environment';
import { AuthService } from '@app/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.authService.getTokenFromStorage();
        if (token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `${env.httpInterceptors.auth_header_scheme} ${token}`
                }
            });
        }
        return next.handle(request);
    }
}
