<div class="content p-4">
    <div class="content-wrapper row">
        <mat-card appearance="outlined" class="col-12 col-xxl-4" style="height: max-content">
            <mat-card-title>
                User Profile
            </mat-card-title>
            <mat-card-content *ngIf="identity">
                <div class="user-section d-flex" [ngClass]="{'passwordEditSection': isChangePassword}">
                    <mat-icon>person</mat-icon>
                    <div class="user-info">
                        <div class="" [hidden]="isChangePassword">
                            <span>{{ identity.name }}<br>
                                {{ identity.email }}</span><br>
                        </div>
                        <div>
                            <button *ngIf="!isChangePassword; else changePassword" class="mt-2" mat-raised-button
                                    color="primary" (click)="isChangePassword = !isChangePassword">Change Password
                            </button>
                            <ng-template #changePassword>
                                <mat-form-field class="example-full-width" appearance="outline" subscriptSizing="dynamic">
                                    <mat-label>Current Password</mat-label>
                                    <input matInput [(ngModel)]="currentPassword" type="password">
                                </mat-form-field>
                                <mat-form-field class="example-full-width" appearance="outline" subscriptSizing="dynamic">
                                    <mat-label>New Password</mat-label>
                                    <input matInput [(ngModel)]="password" (ngModelChange)="updateError()" type="password">
                                </mat-form-field>
                                <mat-form-field class="example-full-width" appearance="outline" subscriptSizing="dynamic">
                                    <mat-label>Confirm Password</mat-label>
                                    <input matInput [(ngModel)]="passwordRepeat" (ngModelChange)="updateError()" (keyup.enter)="onSave()" type="password">
                                </mat-form-field>
                                <p class="error">{{ passwordError }}</p>
                                <div>
                                    <button class="save-password-button" mat-raised-button color="primary"
                                            (click)="onSave()" [disabled]="!isPasswordValid()">Save
                                    </button>
                                    <button class="cancel-button" mat-raised-button (click)="cancel()">Cancel</button>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>

                <mat-tab-group mat-stretch-tabs="false">
                    <mat-tab label="Groups">
                        <mat-card appearance="outlined">
                            <mat-card-content>
                                @if (!groups || groups.length) {
                                    <div>
                                        <mat-list *ngFor="let group of groups">
                                            <mat-list-item class="px-3">{{ group.name }}</mat-list-item>
                                            <mat-divider></mat-divider>
                                        </mat-list>
                                    </div>
                                } @else {
                                    <div class="no-data-row">
                                        <mat-icon class="material-icons-outlined">info</mat-icon>
                                        No groups
                                    </div>
                                }
                            </mat-card-content>
                        </mat-card>
                    </mat-tab>
                    <mat-tab label="Preferences" *ngIf="appSettings?.email_notifications">
                        <mat-card appearance="outlined">
                            <mat-card-content>
                                <div class="row m-3">
                                    <mat-slide-toggle class="table-header-button"
                                                      [(ngModel)]="settingEmailNotifications">
                                        Allow email notifications
                                    </mat-slide-toggle>
                                </div>
                                <button (click)="setUserSettings(allSettings)" class="m-2" mat-stroked-button>
                                    Cancel
                                </button>
                                <button class="m-2" mat-raised-button color="primary" (click)="saveUserSettings()">
                                    Update Settings
                                </button>
                            </mat-card-content>
                        </mat-card>
                    </mat-tab>
                </mat-tab-group>

            </mat-card-content>
        </mat-card>
        <mat-card appearance="outlined" class="col">
            <mat-card-title>
                Documents
                <span class="search-form-field">
                                    <mat-icon class="search-icon">search</mat-icon>
                                    <span class="search-field">
                                        <input matInput [(ngModel)]="searchKeyword" (keyup)="applyFilter($event)">
                                    </span>
                                    <span class="cancel-button-wrapper">
                                        <mat-icon [hidden]="searchKeyword.length < 1" class="cancel-button"
                                                  (click)="cancelSearch()">cancel</mat-icon>
                                    </span>
                                </span>
            </mat-card-title>
            <mat-card-content>
                @if (documentsDataSource?.data?.length && documentsDataSource?.filteredData?.length) {
                    <table matSort mat-table [dataSource]="documentsDataSource" class="document-table">

                        <ng-container matColumnDef="title">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                sortActionDescription="Sort by title">Title
                            </th>
                            <td mat-cell *matCellDef="let element">{{ element.title }}</td>
                        </ng-container>

                        <ng-container matColumnDef="ctime">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                sortActionDescription="Sort by creation time">Date Added
                            </th>
                            <td mat-cell *matCellDef="let element">{{ element.getCreatedTime().format(dateFormat) }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="expiry">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                sortActionDescription="Sort by expiry">Expiry
                            </th>
                            <td mat-cell *matCellDef="let element">
                                @if (element.expiry) {
                                    {{ element.getDashboardExpirationTime().format(revisionDateFormat) }}
                                }
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr
                            mat-row
                            (click)="viewCurrentDocument(row.id)"
                            *matRowDef="let row; columns: displayedColumns;"
                        ></tr>
                    </table>
                } @else {
                    <div class="no-data-row p-3">
                        <mat-icon class="material-icons-outlined">info</mat-icon>
                        No documents found
                    </div>
                }
            </mat-card-content>
        </mat-card>
    </div>
</div>
