import { Component, OnInit } from '@angular/core';
import { AuthGroup, User } from '@app/models';
import { ActivatedRoute, Router } from '@angular/router';
import { Log } from '@app/helpers';
import { AuthService, BreadcrumbService } from '@app/core';
import { UserService } from '@app/services/user.service';
import { MessageService } from '@app/services/message.service';
import { GroupService } from '@app/services/group.service';
import { SecurityTokenService } from '@app/services/security-token.service';
import { UserInvitationService } from '@app/services/user-invitation.service';
import { forkJoin } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { DialogConfirmComponent, DialogConfirmData } from '@app/components/dialogs/shared/dialog-confirm/dialog-confirm.component';
import { environment as env } from '@env/environment';
import * as moment from 'moment';

@Component({
    selector: 'devfu-security-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss']
})
export class SecurityUsersComponent implements OnInit {

    user: User = null;
    hide = true;
    passwordRepeat: string = null;
    groups: AuthGroup[] = [];
    userGroups: AuthGroup[];

    selectedGroups: number[] = [];
    groupsCopy: AuthGroup[] = [];

    invitationID: number = null;
    email: string = null;
    token: string = null;

    adding = false;
    removing = false;

    timezoneOptions: string[] = [];

    constructor(private groupService: GroupService,
                private router: Router,
                private userService: UserService,
                private messageService: MessageService,
                private breadcrumbService: BreadcrumbService,
                private activatedRoute: ActivatedRoute,
                private tokenService: SecurityTokenService,
                private inviteService: UserInvitationService,
                private route: ActivatedRoute,
                private dialog: MatDialog,
                private authService: AuthService) {
    }

    ngOnInit() {
        this.activatedRoute.params.subscribe(params => {
            Log.d('User params:', params);
            if (params['id']) {
                this.groupService.getAll().subscribe(groups => {
                    this.groups = groups;
                });
                if (params['id'] === 'new') {
                    this.createBlankUser();
                } else {
                    this.loadUser(params['id']);
                }
            } else {
                this.email = this.route.snapshot.queryParamMap.get('email');
                this.token = this.route.snapshot.queryParamMap.get('token');
                this.tokenService.checkInvitationToken(this.email, this.token).subscribe({
                    next: ui => {
                        Log.d('Security token is valid');
                        this.user = User.assign(ui);
                        this.invitationID = this.user.id;
                        this.user.id = null;
                        this.user.tz = env.defaultTimezone;
                    },
                    error: () => {
                        this.messageService.showMessage({
                            'title': 'Invalid token',
                            'message': 'The provided token is invalid',
                            duration: 3000,
                            icon: 'fas fa-times-circle'
                        });
                        Log.d('Security token not valid');
                        this.authService.logout();
                    }
                });
            }
        });
    }

    createBlankUser() {
        this.breadcrumbService.setCurrentPath([
            { label: 'Security', path: ['/security'] },
            { label: 'User', path: ['/security'] },
            { label: 'New user', path: null }
        ]);

        this.user = new User();
        this.user.tz = env.defaultTimezone;
        this.user.enabled = true;
        this.searchTimezone();
    }

    loadUser(id: number) {
        forkJoin([
            this.userService.get(id),
            this.userService.getUserGroups(id)
        ]).subscribe(([user, groups]) => {
            this.user = User.assign(user);
            this.userGroups = groups;
            this.groupsCopy = this.groups.filter(g => !this.userGroups.map(ug => ug.id).includes(g.id));

            this.searchTimezone();

            this.breadcrumbService.setCurrentPath([
                { label: 'Security', path: ['/security'] },
                { label: 'User', path: ['/security'] },
                { label: this.user.name, path: null }
            ]);
        });
    }

    onSaveChanges() {
        if (this.invitationID) {
            this.acceptInvitation();
            return;
        }
        if (this.user.id === 0) {
            this.userService.create(this.user).subscribe({
                next: user => {
                    this.messageService.showMessage({
                        'title': 'User created',
                        'message': 'New user was created successfully',
                        duration: 3000,
                        icon: 'fas fa-check-circle'
                    });
                    this.router.navigate(['/settings/security/dashboard']);
                },
                error: (e) => {
                    Log.d(e);
                    this.messageService.showMessage({
                        'title': 'User created',
                        'message': 'Failed to create new user',
                        duration: 3000,
                        icon: 'fas fa-times-circle'
                    });
                }
            });
        } else {
            this.userService.update(this.user).subscribe({
                next: user => {
                    this.messageService.showMessage({
                        'title': 'User updated',
                        'message': 'User information updated successfully',
                        duration: 3000,
                        icon: 'fas fa-check-circle'
                    });
                    this.router.navigate(['/settings/security/dashboard']);
                },
                error: (e: any) => {
                    Log.d(e);
                    this.messageService.showMessage({
                        'title': 'User updated',
                        'message': 'Failed to update user',
                        duration: 3000,
                        icon: 'fas fa-times-circle'
                    });
                }
            });
        }
    }

    acceptInvitation(): void {
        this.inviteService.acceptInvitation(this.invitationID, this.user, this.email, this.token).subscribe({
            next: () => {
                this.messageService.showMessage({
                    'title': 'User created',
                    'message': 'New user was created successfully',
                    duration: 3000,
                    icon: 'fas fa-check-circle'
                });
                this.authService.logout();
            },
            error: (e) => {
                Log.d(e);
                this.messageService.showMessage({
                    'title': 'User created',
                    'message': 'Failed to create new user',
                    duration: 3000,
                    icon: 'fas fa-times-circle'
                });
            }
        });
    }

    isValid() {
        return this.user.name && this.user.name.length > 1 &&
            this.user.email && this.user.email.length > 5 &&
            this.user.email.indexOf('@') > 0 &&
            (this.user.id ||
                (
                    this.user.password && this.passwordRepeat &&
                    this.user.password === this.passwordRepeat
                )
            )  // Need a password for new users
            && moment.tz.names().includes(this.user.tz);
    }

    isTimezoneValid(){
        return moment.tz.names().includes(this.user.tz);
    }

    onCancel() {
        this.router.navigate(['/settings/security/dashboard'], { queryParams: { 't': 0 } });
    }

    async addUserToGroups() {
        if (this.adding || !this.user?.id || !this.selectedGroups || this.selectedGroups.length < 1) {
            return;
        }
        this.adding = true;
        let successFullAdd = true;
        for (const groupID of this.selectedGroups) {
            const response = await this.groupService.addUserToGroup(groupID, this.user.id);
            successFullAdd = successFullAdd && !!response.group_id;
        }

        if (successFullAdd) {
            this.messageService.showMessage({
                'title': 'User updated',
                'message': 'User successfully added to groups',
                duration: 3000,
                icon: 'fas fa-check-circle'
            });
            this.selectedGroups = [];
            this.userService.getUserGroups(this.user.id).subscribe(groups => {
                this.userGroups = groups;
                this.groupsCopy = this.groups.filter(g => !groups.map(ug => ug.id).includes(g.id));
            });
        } else {
            this.messageService.showMessage({
                'title': 'User update failed',
                'message': 'Failed to add user to groups',
                duration: 3000,
                icon: 'fas fa-times-circle'
            });
        }
        this.adding = false;
    }

    removeUserFromGroup(group: AuthGroup): void {
        if (this.removing || !this.user?.id) {
            return;
        }
        const confirmDialog = this.dialog.open(DialogConfirmComponent, {
            data: new DialogConfirmData(`Are you sure you want to remove ${this.user.name ?? 'this user'} from the ${group.name} group?`)
        });

        confirmDialog.afterClosed().subscribe((result: boolean) => {
            if (result) {
                this.removing = true;
                this.groupService.removeUserFromGroup(group.id, this.user.id).then(response => {
                    if (response.status === 'OK') {
                        this.messageService.showMessage({
                            'title': 'User updated',
                            'message': 'User successfully removed from group',
                            duration: 3000,
                            icon: 'fas fa-check-circle'
                        });
                        this.userService.getUserGroups(this.user.id).subscribe(groups => {
                            this.userGroups = groups;
                            this.groupsCopy = this.groups.filter(g => !groups.map(ug => ug.id).includes(g.id));
                        });
                    } else {
                        this.messageService.showMessage({
                            'title': 'User update failed',
                            'message': 'Failed to remove user from group',
                            duration: 3000,
                            icon: 'fas fa-times-circle'
                        });
                    }
                }).finally(() => this.removing = false);
            }
        });
    }

    searchTimezone(): void {
        if (!this.user || this.user.tz.trim() === '') {
            this.timezoneOptions = moment.tz.names().slice(0, 30);
        }
        this.timezoneOptions = moment.tz.names().filter(tz =>
            tz.trim().toLowerCase().includes(this.user?.tz.trim().toLowerCase())).slice(0, 30);
    }

    selectTimezone(): void {
        if (this.timezoneOptions.length > 0) {
            this.user.tz = this.timezoneOptions[0];
        }
    }
}
