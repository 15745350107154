import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '@app/core';
import { CacheHelper, downloadBlob, Log } from '@app/helpers';
import { Document, DocumentFormat } from '@app/models';
import { map, tap } from 'rxjs/operators';
import { RestResponseModel } from '@app/models/rest-response.model';
import { CacheService } from '@app/services/cache.service';
import { CoreService } from '@app/services/core.service';

@Injectable({
    providedIn: 'root'
})
export class FormatService extends CoreService {

    cache = new CacheHelper<DocumentFormat>();
    documentCache: CacheHelper<Document>;

    constructor(protected override auth: AuthService, protected http: HttpClient, private cacheService: CacheService) {
        super('document', auth);
        Log.c('Format Service Ready...');
        this.documentCache = this.cacheService.get<Document>('document');
    }

    create(documentID: number, format: DocumentFormat): Observable<DocumentFormat> {
        this.documentCache.invalidate(documentID);
        return this.http.post<DocumentFormat>(this.endpoint(`${documentID}/format`), JSON.stringify(format), this.options).pipe(
            map(DocumentFormat.assign)
        );
    }

    update(documentID: number, format: DocumentFormat): Observable<DocumentFormat> {
        this.cache.invalidate(format.id);
        this.documentCache.invalidate(documentID);
        return this.cache.return(format.id,
            this.http.put<DocumentFormat>(this.endpoint(`${documentID}/format/${format.id}`), JSON.stringify(format), this.options).pipe(
                map(DocumentFormat.assign)
            )
        );
    }

    patchOrdinal(documentID: number, formatID: number, newOrdinal: number): Observable<DocumentFormat> {
        this.cache.invalidate(formatID);
        this.documentCache.invalidate(documentID);
        return this.cache.return(formatID,
            this.http.patch<DocumentFormat>(this.endpoint(`${documentID}/format/${formatID}`), { ordinal: newOrdinal }, this.options).pipe(
                map(DocumentFormat.assign)
            )
        );
    }

    patchName(documentID: number, formatID: number, name: string): Observable<DocumentFormat> {
        this.cache.invalidate(formatID);
        this.documentCache.invalidate(documentID);
        return this.cache.return(formatID,
            this.http.patch<DocumentFormat>(this.endpoint(`${documentID}/format/${formatID}`), { name: name }, this.options).pipe(
                map(DocumentFormat.assign)
            )
        );
    }

    delete(documentID: number, formatID: number): Observable<RestResponseModel> {
        this.cache.invalidate(formatID);
        this.documentCache.invalidate(documentID);
        return this.http.delete<RestResponseModel>(this.endpoint(`${documentID}/format/${formatID}`), this.options);
    }

    download(documentID: number, formatID: number): Observable<any> {
        return this.http.get(this.endpoint(`${documentID}/format/${formatID}/download`), {
            responseType: 'blob', observe: 'response'
        }).pipe(
            tap((response: HttpResponse<Blob>) => {
                const contentDisposition = response.headers.get('Content-Disposition');
                const filename = contentDisposition
                    ? contentDisposition.match(/filename=([^;]+)/)?.[1]
                    : `document${documentID}-format${formatID}.zip`;
                downloadBlob(response.body, filename);
            })
        );
    }
}
