import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { LoadingState } from '@app/core/loading-state.enum';
import { Router } from '@angular/router';
import { environment as env } from '@env/environment';
import { AuthService } from '@app/core';
import { lastValueFrom, Subscription } from 'rxjs';
import { Notification, User } from '@app/models';
import { MatPaginator } from '@angular/material/paginator';
import { NotificationService } from '@app/services/notification.service';


@Component({
    selector: 'devfu-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy {
    notifications: Notification[] = [];
    dataSource = new MatTableDataSource<Notification>();
    displayedColumns = ['time', 'title', 'content', 'actions'];
    notificationState: LoadingState = LoadingState.Loading;
    dateFormat = env.defaultDateFormat;

    showDismissed = false;
    identity: User;

    private isLoadingNotifications = false;
    private notificationSubscription: Subscription;
    private authSubscription: Subscription;

    constructor(private notificationService: NotificationService,
                private authService: AuthService,
                private router: Router) {
        this.notificationSubscription = this.notificationService.getNotificationSubject().subscribe(() => {
            if (!this.isLoadingNotifications) {
                this.loadData();
            }
        });
    }

    @ViewChild(MatPaginator) set matPaginator(paginator: MatPaginator) {
        this.dataSource.paginator = paginator;
    }

    // Expose LoadingState to template
    get LoadingState() {
        return LoadingState;
    }

    ngOnInit() {
        const handleAuth = async () => {
            const isAuthenticated = this.authService.getLoginStateFromStorage();
            if (isAuthenticated) {
                this.identity = await lastValueFrom(this.authService.getIdentityFromServer());
                if (!this.isLoadingNotifications) {
                    this.loadData();
                }
            }
        };

        this.authSubscription = this.authService.onStateUpdated.subscribe(handleAuth);
        handleAuth().then();
    }

    ngOnDestroy() {
        this.notificationSubscription.unsubscribe();
        this.authSubscription.unsubscribe();
    }

    loadData(): void {
        this.isLoadingNotifications = true;
        this.notificationService.getNotifications().subscribe({
            next: (notifications: Notification[]) => {
                this.notifications = notifications;
                if (notifications.length) {
                    this.showNotifications();
                    this.notificationState = LoadingState.LoadedOK;
                } else {
                    this.notificationState = LoadingState.LoadedNoData;
                }
                this.isLoadingNotifications = false;
            },
            error: () => {
                this.notificationState = LoadingState.ErrorLoading;
                this.isLoadingNotifications = false;
            }
        });
    }

    goToNotification(targetPath: string): void {
        this.router.navigateByUrl(targetPath).then();
    }

    showNotifications(): void {
        this.dataSource.data = (this.showDismissed ? this.notifications.slice() : this.notifications.filter(f => !f.is_read))
            .sort((a, b) => b.ctime.getTime() - a.ctime.getTime());
    }

    markNotificationRead(notificationID: number): void {
        this.isLoadingNotifications = true;
        const target = this.notifications.find(n => n.id === notificationID);
        if (!target) {
            this.loadData();
        }
        target.is_read = true;
        this.showNotifications();
        this.notificationService.markAsRead(notificationID).subscribe(() => this.isLoadingNotifications = false);
    }

    deleteNotification(notificationID: number): void {
        this.isLoadingNotifications = true;
        const target = this.notifications.find(n => n.id === notificationID);
        if (!target) {
            this.loadData();
            return;
        }
        this.notificationService.delete(notificationID).subscribe(notifications => {
            this.notifications = notifications;
            this.showNotifications();
            this.isLoadingNotifications = false;
        });
    }

    markAllNotificationsRead(): void {
        this.isLoadingNotifications = true;
        const affected = this.notifications.filter(n => !n.is_read);
        if (affected.length < 1) {
            this.loadData();
            return;
        }
        affected.forEach(n => n.is_read = true);
        this.showNotifications();
        this.notificationService.markAllRead(affected.map(n => n.id)).subscribe(n => {
            this.notifications = n;
            this.isLoadingNotifications = false;
        });
    }
}
