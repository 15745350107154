import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from './auth/interceptors/token.interceptor';
import { JwtInterceptor } from './auth/interceptors/jwt.interceptor';
import { environment as env } from '@env/environment';
import { NullInterceptor } from './auth/interceptors/null.interceptor';
import { BreadcrumbService } from './breadcrumb/breadcrumb.service';
import { AuthGuard } from './auth/auth.guard';
import { AuthService, NetworkService } from '@app/core';
import { GroupService } from '@app/services/group.service';
import { VersionService } from '@app/services/version.service';
import { FormatService } from '@app/services/format.service';
import { ReviewService } from '@app/services/review.service';
import { FileService } from '@app/services/file.service';
import { SearchService } from '@app/services/search.service';
import { UserService } from '@app/services/user.service';
import { UserSettingService } from '@app/services/user-setting.service';
import { TagService } from '@app/services/tag.service';
import { TransmissionService } from '@app/services/transmission.service';
import { MessageService } from '@app/services/message.service';
import { UserInvitationService } from '@app/services/user-invitation.service';
import { SecurityTokenService } from '@app/services/security-token.service';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule
    ],
    providers: [
        NetworkService,
        env.httpInterceptors.enabled ? {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        } : NullInterceptor,
        env.httpInterceptors.enabled ? {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true
        } : NullInterceptor,
        VersionService,
        FormatService,
        ReviewService,
        FileService,
        SearchService,
        BreadcrumbService,
        AuthGuard,
        SecurityTokenService,
        GroupService,
        UserService,
        UserInvitationService,
        UserSettingService,
        TagService,
        TransmissionService,
        MessageService,
        FileService
    ]
})
export class CoreModule {
    constructor(
        @Optional()
        @SkipSelf()
            parentModule: CoreModule
    ) {
        if (parentModule) {
            throw new Error('CoreModule is already loaded. Import only in AppModule');
        }
    }
}
