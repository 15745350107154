import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CoreService } from '@app/services/core.service';
import { RestResponseModel, User, UserInvitation } from '@app/models';
import { AuthService } from '@app/core';
import { Log } from '@app/helpers';
import { map } from 'rxjs/operators';

@Injectable()
export class UserInvitationService extends CoreService {
    constructor(protected override auth: AuthService, protected http: HttpClient) {
        super('user_invitation', auth);
        Log.c('UserInvitationService Ready...');
    }

    getAll(): Observable<UserInvitation[]> {
        return this.http.get<UserInvitation[]>(this.endpoint(), this.options).pipe(
            map(ui => ui.map(UserInvitation.assign))
        );
    }

    create(invitation: UserInvitation): Observable<UserInvitation> {
        return this.http.post<UserInvitation>(this.endpoint(), JSON.stringify(invitation), this.options).pipe(
            map(UserInvitation.assign)
        );
    }

    acceptInvitation(userInvitationID: number, user: User, email: string, token: string): Observable<RestResponseModel> {
        const data = {
            email: email,
            token: token,
            new_pass: user.password,
            tz: user.tz,
            name: user.name
        };
        return this.http.post<RestResponseModel>(this.endpoint(`accept/${userInvitationID}`), data, this.options);
    }

    resendActivationEmail(userInvitationID: number): Observable<RestResponseModel> {
        return this.http.post<RestResponseModel>(this.endpoint(`resend/${userInvitationID}`), this.options);
    }

    delete(userInvitationID: number): Observable<RestResponseModel> {
        return this.http.delete<RestResponseModel>(this.endpoint(userInvitationID), this.options);
    }
}
