<div class="col-lg-6 col-xl-5 col-md-8 mt-md-4 pt-md-5 mx-auto text-center">
    <img class="logo w-50 mx-auto" src="/assets/anthem_badge.png"/>
    <div class="row">
        <div class="col-lg-10 mt-4 mx-auto">
            <p><small>Please sign in to access the dashboard</small></p>
            <p *ngIf="showSessionExpiredMessage && !error" class="text-danger"><small>Session expired! Please log in
                again.</small></p>
            <mat-form-field class="w-75" appearance="outline">
                <mat-label>Email</mat-label>
                <input (keyup.enter)="onLogin()" [(ngModel)]="loginData.username" [ngModelOptions]="{standalone: true}" matInput type="email">
            </mat-form-field>
            <mat-form-field class="w-75" appearance="outline">
                <mat-label>Password</mat-label>
                <input (keyup.enter)="onLogin()" [(ngModel)]="loginData.password" [ngModelOptions]="{standalone: true}"
                       matInput type="password">
            </mat-form-field>
            <div *ngIf="error" class="w-100 py-1 error-message">{{prettifyError('http_' + error.status)}}</div>
        </div>
        <div class="col-lg-10 mx-auto mb-5">
            <button (click)="onLogin()" [disabled]="isLoading || !loginData.password || !loginData.username"
                    class="m-2 mat-elevation-z0 w-25" color="primary"
                    mat-raised-button>
                <span *ngIf="!isLoading">Login</span>
                <span *ngIf="isLoading && !error">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
            </button>
            <button *ngIf="settings?.allow_sign_ups" [routerLink]="['/sign-up']"
                    class="m-2 mat-elevation-z0 w-25" color="primary"
                    mat-raised-button>
                <span>Sign-Up</span>
            </button>
            <div><small><a routerLink="/forgotten-password" [queryParams]="{'email': loginData.username}">
                Forgotten Password?
            </a></small>
            </div>
            <div #googleSignIn class="mt-3 d-flex justify-content-center"></div>
        </div>
    </div>
</div>
