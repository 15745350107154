import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@app/core';
import { Log } from '@app/helpers';
import { Observable } from 'rxjs';
import { CoreService } from '@app/services/core.service';
import { RestResponseModel } from '@app/models';

@Injectable({
    providedIn: 'root'
})
export class SecurityTokenService extends CoreService {

    constructor(protected override auth: AuthService, protected http: HttpClient) {
        super('security_token', auth);
        Log.c('Security Token Service Ready...');
    }

    checkPasswordResetToken(email: string, token: string): Observable<RestResponseModel> {
        return this.checkToken(email, token, 'reset');
    }

    checkEmailVerificationToken(email: string, token: string): Observable<RestResponseModel> {
        return this.checkToken(email, token, 'verification');
    }

    checkInvitationToken(email: string, token: string): Observable<RestResponseModel> {
        return this.checkToken(email, token, 'invitation');
    }

    private checkToken(email: string, token: string, tokenType: string): Observable<RestResponseModel> {
        const data = {
            'email': email,
            'token': token,
            'type': tokenType
        };
        return this.http.post<RestResponseModel>(this.endpoint('check'), data, this.options);
    }
}
