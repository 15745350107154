import { Component } from '@angular/core';

@Component({
    selector: 'devfu-no-network-message',
    templateUrl: './no-network-message.component.html',
    styleUrls: ['./no-network-message.component.scss']
})
export class NoNetworkMessageComponent {

    public constructor() {

    }

}
