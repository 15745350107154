import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Log } from '@app/helpers';
import { User } from '@app/models';
import { UserService } from '@app/services/user.service';
import { MessageService } from '@app/services/message.service';
import { HttpErrorResponse } from '@angular/common/http';

/*
 * The email address validation isn't working properly. I can't work out why so
 * for now I'm just working around it -AGW
 *
 */

const passwordsMatch: ValidatorFn = (control: UntypedFormGroup): ValidationErrors|null => {
    const password = control.get('password');
    const password2 = control.get('password2');

    return password && password2 && password.value === password2.value ? null : { 'mismatch': true };
};

@Component({
    selector: 'devfu-sign-up',
    templateUrl: './sign-up.component.html',
    styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

    public form = new UntypedFormGroup({
        name: new UntypedFormControl('', [Validators.required]),
        email: new UntypedFormControl('', [Validators.required, Validators.email]),
        password: new UntypedFormControl('', [Validators.required]),
        password2: new UntypedFormControl('', [Validators.required])
    }, { validators: passwordsMatch });

    // For some reason we can't check form.errors, so we'll force an error until we can work it out
    forceErrors = false;
    isLoading = false;
    done = false;
    signUpErrors = false;

    constructor(private userService: UserService,
                private messageService: MessageService) {
    }

    get name() {
        return this.form.get('name');
    }

    get email() {
        return this.form.get('email');
    }

    get password() {
        return this.form.get('password');
    }

    get password2() {
        return this.form.get('password2');
    }

    get errors() {
        return this.form.errors;
    }

    ngOnInit() {
    }

    loginOk() {
        return this.form.valid;
    }

    getErrorMessage(fieldName: string) {
        const f = this.form.get(fieldName);
        return f.hasError('required') ? 'You must enter a value' :
            f.hasError('email') ? 'Not a valid email' :
                f.invalid ? 'Invalid Value' : '';
    }

    doSignup() {
        if (this.isLoading) {
            return;
        }
        if (this.form.errors) {
            this.forceErrors = true;
            return;
        }
        this.forceErrors = false;

        const u = User.assign(this.form.value);

        Log.d('Errors:', this.form.errors);
        Log.d('do signup!');
        Log.d(u);

        this.isLoading = true;
        this.userService.signUp(u).subscribe({
            next: () => {
                this.done = true;
            },
            error: (e: HttpErrorResponse) => {
                const message = e.status === 409 ? 'This user already exists.' : 'An error occurred during sign-up.';
                this.done = false;
                this.isLoading = false;
                this.messageService.showMessage({
                    'title': 'Sign-up error',
                    'message': message,
                    duration: 3000,
                    icon: 'fas fa-times-circle'
                });
            }
        });
    }
}
