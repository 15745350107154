import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@app/core';
import { Log } from '@app/helpers';
import { CoreService } from '@app/services/core.service';
import { Observable, of, shareReplay } from 'rxjs';
import { EulaModel } from '@app/models';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class EulaService extends CoreService {

    acceptanceEndpoint = this.baseEndpoint() + 'eula_acceptance';

    constructor(protected override auth: AuthService, protected http: HttpClient) {
        super('eula', auth);
        Log.c('Eula Service Ready...');
    }

    getActiveEula(): Observable<EulaModel> {
        return this.http.get<EulaModel>(this.endpoint('current'), this.options);
    }

    acceptEula() {
        return this.http.post(this.acceptanceEndpoint, JSON.stringify({}), this.options).pipe(
            tap(() => {
                this.auth.eulaAcceptedObservable = null;
                this.auth.onStateUpdated.emit();
            })
        );
    }

    checkEulaAccepted(useCache: boolean = true): Observable<boolean> {
        if (!this.auth.eulaAcceptedObservable || !useCache) {
            this.auth.eulaAcceptedObservable = this.http.get<boolean>(this.acceptanceEndpoint, this.options).pipe(
                shareReplay(1)
            );
        }
        return this.auth.eulaAcceptedObservable;
    }
}
