import { EventEmitter, Injectable } from '@angular/core';
import { Message } from '@app/models';

@Injectable({
    providedIn: 'root'
})
export class MessageService {
    public onMessage: EventEmitter<Message> = new EventEmitter<Message>();

    constructor() {
    }

    public showMessage(message: Message) {
        this.onMessage.emit(message);
    }
}
