import { Component, OnInit } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';

@Component({
    selector: 'devfu-dialog-sync-expiry',
    templateUrl: './dialog-sync-expiry.component.html',
    styleUrls: ['./dialog-sync-expiry.component.scss'],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'en-AU' },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
    ]
})
export class DialogSyncExpiryComponent implements OnInit {
    minVersionExpiryDate = new Date();
    newExpiry = '';
    isSync = false;

    constructor() {
    }

    ngOnInit(): void {
    }

}
