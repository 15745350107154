import {ISecurityEntryModel, SecurityEntry} from '@app/models/security.models';
import {Document} from '@app/models/documents.models';

export class CategoryDocument {
    public id: number;
    public document_id: number;
    public category_id: number;
    public document_version_id: number;
    public ordinal: number;
}

export class Category {
    public id: number;
    public parent_category_id: number;
    public ordinal: number;
    public title: string;
    public is_sorted: boolean;

    public categories: Category[];
    public documents: Document[];
    public atlas_id: number;
}

export class Atlas implements ISecurityEntryModel {
    public id = 0;
    public owner_id = 0;
    public name = '';
    public description = '';
    public is_private = false;
    public entries: AtlasEntry[] = [];

    public security: SecurityEntry[] = [];
    public readonly modelType: string = 'atlas';

    public static assign(o: Atlas): Atlas {
        const n: Atlas = Object.assign(new Atlas(), o);

        for (const idx in n.entries) {
            if (idx) {
                n.entries[idx] = AtlasEntry.assign(n.entries[idx]);
            }
        }

        n.is_private = !!o.is_private;

        return n;
    }

    public getCategoryCount(): number {

        const getCount = (entries: AtlasEntry[]) => {
            let count = 0;
            for (const entry of entries) {
                const categories = entry.children.filter(e => e.category_id !== 0);
                if (categories.length > 0) {
                    count += getCount(categories);
                }

                count++;
            }
            return count;
        };

        return getCount(this.entries);

    }

    public getDocumentCount(): number {

        const getCount = (entries: AtlasEntry[]) => {
            let count = 0;
            for (const entry of entries) {
                const categories = entry.children.filter(e => e.document_id !== 0);
                if (categories.length > 0) {
                    count += getCount(categories);
                }

                count++;
            }
            return count;
        };

        return getCount(this.entries);

    }

    public getCategories(): AtlasEntry[] {
        return this.entries.filter(e => e.category_id > 0).sort((a, b) => {
            return a.ordinal - b.ordinal;
        });
    }

    public getDocuments(): AtlasEntry[] {
        return this.entries.filter(e => e.document_id > 0);
    }

}

export interface NestedEntry<T> {
    children: T[];
}

export class AtlasEntry implements NestedEntry<AtlasEntry> {
    public id: number;
    public document_category_id = 0;
    public category_id = 0;   // if category_id is non-zero it's a category
    public document_id = 0;   // if this is non-zero, this entry is a document
    public parent_id = 0;     // this is a category ID. Documents can't be parents of categories
    public ordinal = 0;      // used server side to order things, needs to be settable for reordering individual entries
    public title = '';        // Text to display for this category
    public children: AtlasEntry[] = [];   // Will only be populated if category_id != 0
    public is_private = false;
    public is_sorted = false;
    public isAddEntry = false; // Just for UI purposes

    public static assign(o: AtlasEntry): AtlasEntry {
        const n: AtlasEntry = Object.assign(new AtlasEntry(), o);
        for (const idx in n.children) {
            if (n.children && n.children.length > 0) {
                n.children[idx] = this.assign(n.children[idx]);
            }
        }
        return n;
    }


    public isDocument() {
        return this.document_id > 0;
    }

    public getCategoryCount(recursive = true): number {

        const getCount = (entries: AtlasEntry[], inner_recursive: boolean) => {
            let count = 0;
            for (const entry of entries) {
                const categories = entry.children.filter(e => e.category_id !== 0);
                if (categories.length > 0 && inner_recursive) {
                    count += getCount(categories, inner_recursive);
                }

                if (entry.category_id !== 0) {
                    count++;
                }
            }
            return count;
        };

        return getCount(this.children, recursive);

    }

    public getDocumentCount(recursive = true): number {

        const getCount = (entries: AtlasEntry[]) => {
            let count = 0;
            for (const entry of entries) {
                const categories = entry.children.filter(e => e.document_id !== 0);
                if (categories.length > 0 && recursive) {
                    count += getCount(categories);
                }

                if (entry.document_id !== 0) {
                    count++;
                }
            }
            return count;
        };

        return getCount(this.children);

    }

    public getCategories(): AtlasEntry[] {
        return this.children.filter(e => e.category_id > 0);
    }

    public getDocuments(): AtlasEntry[] {
        return this.children.filter(e => e.document_id > 0);
    }

    public getChildren(): AtlasEntry[] {
        return this.children.sort((a, b) => {
            return a.ordinal - b.ordinal;
        });
    }

    isEmpty() {
        return this.document_category_id === 0 && this.category_id === 0;
    }
}
