const packageJson = require('../../package.json');

export const environment = {
    appName: 'Anthem',
    envName: 'PROD',
    production: true,
    versions: {
        serverBaseUrl: '',
        app: packageJson.version,
        angular: packageJson.dependencies['@angular/core'],
        ngrx: packageJson.dependencies['@ngrx/store'],
        material: packageJson.dependencies['@angular/material'],
        bootstrap: packageJson.dependencies.bootstrap,
        rxjs: packageJson.dependencies.rxjs,
        angularCli: packageJson.devDependencies['@angular/cli'],
        typescript: packageJson.devDependencies['typescript']
    },
    themes: [
        {value: 'DEFAULT-THEME', label: 'Default'},
        {value: 'LIGHT-THEME', label: 'Light'},
        {value: 'BLACK-THEME', label: 'Black'},
        {value: 'BLUE-THEME', label: 'Blue'}
    ],
    httpInterceptors: {
        enabled: true,
        auth_header_scheme: 'JWT',
        fallbackUrl: '/'
    },
    httpSettings: {
        retryEnabled: false,
        maxNumberTriesBeforeFail: 3,
        retryInterval: 2000,
        concurrentImageRequests: 5,
        cacheRetryInterval: 3000,
        maxUploadSize: 262144000, // 250MB
        maxInitialImageRequests: 10,
        pingInterval: 5000
    },
    enablePrettyErrors: true,
    navigation: [
        {link: 'dashboard', label: 'Dashboard', icon: 'fas fa-home', admin: false},
        {link: 'settings', label: 'Settings', icon: 'fas fa-cog', admin: true}
    ],
    defaultVersionPrefix: 'V',
    defaultTimezone: 'Australia/Sydney',
    defaultDateFormat: 'D/MM/YYYY hh:mma',
    defaultDateFormatWithoutTimezone: 'D/MM/YYYY hh:mm',
    revisionDateFormat: 'D/MM/YYYY',
    serverDateTimeFormat: 'YYYY-MM-DD HH:mm',
    serverDateFormat: 'YYYY-MM-DD',
    notificationFormat: 'D/MM/YY H:mm',
    pagesToLoadForPaging: 30,
    security: {
        allowMultipleGroupSelection: false,
        allowMultipleUserSelection: false,
        storageKeyToken: 'TOKEN',
        storageKeyLoginState: 'AUTHENTICATED',
        storageKeyIdentity: 'IDENTITY'
    },
    imageSizes: {
        thumbnail: 250,
        full: 4000
    },
    logLevel: 7 // Enable error, warning, and info
};
