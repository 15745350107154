import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CoreService} from '@app/services/core.service';
import {AuthService} from '@app/core';
import {AtlasSecurity} from '@app/models/atlas-security.model';
import { joinPath, Log } from '@app/helpers';

@Injectable()
export class AtlasSecurityService extends CoreService {
    constructor(protected override auth: AuthService, protected http: HttpClient) {
        super('atlas_security', auth);
        Log.c('AtlasSecurityService Ready...');
    }

    getPath(atlasID: number, atlasSecurityID = undefined) {
        return joinPath(this.baseEndpoint(), 'atlas', atlasID, 'security', atlasSecurityID);
    }

    getAtlasSecurity(atlasID: number): Observable<AtlasSecurity[]> {
        return this.http.get<AtlasSecurity[]>(this.getPath(atlasID), this.options);
    }

    getAtlasSecurityAudit(atlasID: number): Observable<AtlasSecurity[]> {
        return this.http.get<AtlasSecurity[]>(this.getPath(atlasID, 'audit'), this.options);
    }

    createAtlasSecurity(atlasSecurity: AtlasSecurity): Observable<AtlasSecurity> {
        return this.http.post<AtlasSecurity>(this.getPath(atlasSecurity.atlas_id), JSON.stringify(atlasSecurity), this.options);
    }

    updateAtlasSecurity(atlasSecurity: AtlasSecurity): Observable<AtlasSecurity> {
        return this.http.put<AtlasSecurity>(
            this.getPath(atlasSecurity.atlas_id, atlasSecurity.id),
            JSON.stringify(atlasSecurity),
            this.options
        );
    }

    deleteAtlasSecurity(atlasID: number, atlasSecurityID: number): Observable<any> {
        return this.http.delete<any>(this.getPath(atlasID, atlasSecurityID), this.options);
    }
}
