import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { AuthService } from '@app/core';
import { Atlas, AtlasEntry, Category, Document, DocumentCategory, RestResponseModel } from '@app/models';
import { map, tap } from 'rxjs/operators';
import { CacheHelper, downloadBlob, Log } from '@app/helpers';
import { CoreService } from '@app/services/core.service';
import { CacheService } from '@app/services/cache.service';

@Injectable({
    providedIn: 'root'
})
export class AtlasService extends CoreService {

    cache: CacheHelper<Atlas>;

    constructor(protected override auth: AuthService, protected http: HttpClient, private cacheService: CacheService) {
        super('atlas', auth);
        Log.c('Atlas Service Ready...');
        this.cache = this.cacheService.get<Atlas>('atlas');
    }

    getAll(): Observable<Atlas[]> {
        return this.cache.returnAll(
            this.http.get<Atlas[]>(this.endpoint(), this.options)
        ).pipe(
            map(atlases => atlases.map(a => Atlas.assign(a)))
        );
    }

    get(atlasID: number): Observable<Atlas> {
        return this.cache.return(atlasID,
            this.http.get<Atlas>(this.endpoint(atlasID), this.options)
        ).pipe(
            map(a => {
                return Atlas.assign(a);
            })
        );
    }

    create(atlas: Atlas): Observable<Atlas> {
        this.cache.invalidateCollection();
        return this.http.post<Atlas>(this.endpoint(), JSON.stringify(atlas), this.options);
    }

    update(atlas: Atlas): Observable<Atlas> {
        this.cache.invalidate(atlas.id);
        return this.cache.return(atlas.id,
            this.http.put<Atlas>(this.endpoint(atlas.id), JSON.stringify(atlas), this.options));
    }

    delete(atlasID: number): Observable<RestResponseModel> {
        this.cache.invalidate(atlasID);
        return this.http.delete<RestResponseModel>(this.endpoint(atlasID), this.options);
    }

    download(atlas: Atlas): Observable<any> {
        return this.http.get(this.endpoint(atlas.id + '/export'), {
            responseType: 'blob',
            observe: 'response'
        }).pipe(
            tap((response: HttpResponse<Blob>) => {
                downloadBlob(response.body, atlas.name);
            })
        );
    }

    public addTopCategory(atlas: Atlas, title: string): Observable<DocumentCategory> {
        const entry: any = {
            atlas_id: atlas.id,
            title: title,
            parent_category_id: null,
            ordinal: atlas.entries.length,
            is_sorted: false
        };
        this.cache.invalidate(atlas.id);
        return this.http.post<DocumentCategory>(this.endpoint(`${atlas.id}/category`), entry, this.options);
    }

    public addCategoryEntry(atlasID: number, parent: AtlasEntry, categoryTitle: string, ordinal: number): Observable<Category> {
        const entry: any = {
            atlas_id: atlasID,
            title: categoryTitle,
            parent_category_id: parent.category_id,
            ordinal: ordinal,
            is_sorted: false
        };
        this.cache.invalidate(atlasID);
        return this.http.post<Category>(this.endpoint(`${atlasID}/category`), entry, this.options);
    }

    public addDocumentEntry(atlasID: number, parentCategoryID: number, document: Document, ordinal: number): Observable<AtlasEntry> {
        const entry = new AtlasEntry();
        entry.title = document.title;
        entry.document_id = document.id;
        entry.category_id = parentCategoryID;
        entry.ordinal = ordinal;
        this.cache.invalidate(atlasID);
        return this.http.post<AtlasEntry>(this.endpoint(`${atlasID}/document`), entry, this.options);
    }

    public removeEntry(atlasID: number, entry: AtlasEntry): Observable<RestResponseModel> {
        const endpoint = this.endpoint(atlasID)
            + (entry.isDocument() ? `/document/${entry.document_category_id}` : `/category/${entry.category_id}`);
        this.cache.invalidate(atlasID);
        return this.http.delete<RestResponseModel>(endpoint, this.options);
    }

    public switchEntries(atlasID: number, firstEntry: AtlasEntry, secondEntry: AtlasEntry): Observable<any> {
        const endpoints = [this.endpoint(atlasID), this.endpoint(atlasID)];
        endpoints[0] += firstEntry.isDocument()
            ? `/document/${firstEntry.document_category_id}`
            : `/category/${firstEntry.category_id}`;
        endpoints[1] += secondEntry.isDocument()
            ? `/document/${secondEntry.document_category_id}`
            : `/category/${secondEntry.category_id}`;
        firstEntry.ordinal += 1;
        secondEntry.ordinal -= 1;

        this.cache.invalidate(atlasID);
        return forkJoin([
            this.http.put(endpoints[0], firstEntry, this.options),
            this.http.put(endpoints[1], secondEntry, this.options)
        ]);
    }

    public sortEntries(atlasID: number, categoryID: number): Observable<Atlas> {
        this.cache.invalidate(atlasID);
        return this.cache.return(atlasID,
            this.http.post<Atlas>(this.endpoint(`${atlasID}/category/${categoryID}/sort`), {}, this.options)
        ).pipe(
            map(atlas => Atlas.assign(atlas))
        );
    }
}
