import { Title } from '@angular/platform-browser';
import { OverlayContainer } from '@angular/cdk/overlay';
import { Component, ElementRef, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivationEnd, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AuthService, BreadcrumbService, NetworkService, routerTransition } from '@app/core';
import { environment as env } from '@env/environment';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { filter } from 'rxjs/operators';
import { isFullScreen } from '@app/core/fullscreen/fullscreen.interface';
import { NoNetworkMessageComponent } from '@app/components/pages/root/no-network-message/no-network-message.component';
import { MessagesComponent } from '@app/components/pages/root';
import { User } from './models';
import { MessageService } from '@app/services/message.service';
import { RoutingStateService } from '@app/services/routing-state.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'devfu-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [routerTransition]
})
export class AppComponent implements OnInit, OnDestroy {

    @HostBinding('class') componentCssClass: string;

    @ViewChild('badge') badge: ElementRef;
    @ViewChild('sidenav') sidenav: MatSidenav;

    badgeClass = '';
    isProd = env.production;
    envName = env.envName;
    version = env.versions.app;
    year = new Date().getFullYear();
    navigation = env.navigation;
    isAuthenticated: boolean;
    identity: User;
    isAdmin = false;

    protected readonly window = window;
    private hadNetworkError = false;
    private authSubscription: Subscription;

    constructor(public overlayContainer: OverlayContainer,
                private authService: AuthService,
                private router: Router,
                private breadcrumbService: BreadcrumbService,
                private messageService: MessageService,
                private networkService: NetworkService,
                private snackBar: MatSnackBar,
                private titleService: Title,
                private routingStateService: RoutingStateService) {
    }

    ngOnInit(): void {
        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd)
        ).subscribe((event: NavigationEnd) => {
            this.routingStateService.setPreviousUrl(this.routingStateService.getCurrentUrl());
            this.routingStateService.setCurrentUrl(event.url);
        });


        this.router.events.pipe(
            filter((event) => event instanceof NavigationStart)
        ).subscribe((event: NavigationStart) => {
            if (this.identity?.id !== this.authService.getIdentityFromStorage()?.id) {
                location.href = event.url;
            }
        });

        const theme = 'blue-theme';
        this.componentCssClass = theme;

        const classList = this.overlayContainer.getContainerElement().classList;

        const themeClassNames = env.themes.map(e => e.value.toLowerCase());

        classList.remove(...themeClassNames);
        classList.add(theme);

        this.networkService.pollingEvent.subscribe(isConnected => {
            if (isConnected) {
                this.hideNoNetworkOverlay();
            } else {
                this.showNoNetworkOverlay();
            }
        });
        this.networkService.startPolling();

        this.identity = this.authService.getIdentityFromStorage();
        this.isAuthenticated = this.authService.isLoggedIn();

        const handleAuth = () => {
            this.identity = this.authService.getIdentityFromStorage();
            this.isAuthenticated = this.authService.isLoggedIn();
            if (this.isAuthenticated) {
                this.authService.checkIsAdmin().subscribe(isAdmin => this.isAdmin = isAdmin);
            }
        };

        this.authSubscription = this.authService.onStateUpdated.subscribe(handleAuth);
        handleAuth();

        this.messageService.onMessage.subscribe(message => {
            this.snackBar.openFromComponent(MessagesComponent, {
                data: message,
                duration: message.duration
            });
        });

        this.router.events
            .pipe(filter(event => event instanceof ActivationEnd))
            .subscribe((event: ActivationEnd) => {
                let lastChild = event.snapshot;
                while (lastChild.children.length) {
                    lastChild = lastChild.children[0];
                }
                const { title, icon } = lastChild.data;
                this.titleService.setTitle(
                    title ? `${title} - ${env.appName}` : env.appName
                );

                this.badgeClass = icon;
                this.breadcrumbService.setCurrentPath([]);
            });

    }

    ngOnDestroy(): void {
        this.authSubscription.unsubscribe();
    }

    onLogout() {
        this.messageService.showMessage({
            'title': 'Security',
            'message': 'Logout successful',
            duration: 3000,
            icon: 'fas fa-shield-alt'
        });

        this.authService.logout();
        this.sidenav.close().then();

    }

    isFullscreen() {
        return isFullScreen();
    }

    showNoNetworkOverlay() {
        if (!this.hadNetworkError) {
            this.snackBar.openFromComponent(NoNetworkMessageComponent, {
                horizontalPosition: 'center',
                verticalPosition: 'top',
                duration: undefined
            });
            const element: any = document.querySelector('.cdk-global-overlay-wrapper');
            element.style.backgroundColor = '#3a0707f2';
            element.style.pointerEvents = 'all';
            element.style.paddingTop = '15%';
            this.hadNetworkError = true;
        }
    }


    hideNoNetworkOverlay() {
        if (this.hadNetworkError) {
            location.reload();
        }
    }
}
