import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@app/core/auth/auth.guard';
import { UserComponent } from '@app/components/pages/user/user.component';
import { NotificationsComponent } from '@app/components/pages/root';
import { ReportComponent } from './components/pages/reporting/report/report.component';
import { NoAccessComponent } from '@app/components/pages/root/no-access/no-access.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
    },
    {
        path: 'atlas',
        loadChildren: () => import('@app/components/pages/atlas/atlas.module').then(m => m.AtlasModule)
    },
    {
        path: 'tree',
        loadChildren: () => import('@app/components/pages/tree/tree.module').then(m => m.TreeModule)
    },
    {
        path: 'report',
        canActivate: [AuthGuard],
        component: ReportComponent,
    },
    {
        path: 'settings',
        loadChildren: () => import('@app/components/pages/settings/settings.module').then(m => m.SettingsModule)
    },
    {
        path: 'settings/security',
        loadChildren: () => import('@app/components/pages/security/security.module').then(m => m.SecurityModule)
    },
    {
        path: 'user',
        canActivate: [AuthGuard],
        component: UserComponent,
    },
    {
        path: 'notifications',
        canActivate: [AuthGuard],
        component: NotificationsComponent,
        data: { title: 'Notifications', icon: 'fas fa-info-circle' }
    },
    {
        path: '',
        loadChildren: () => import('@app/components/pages/documents/documents.module').then(m => m.DocumentsModule)
    },
    {
        path: 'no-access',
        component: NoAccessComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
