<h2 mat-dialog-title>Sync Expiry</h2>
<mat-dialog-content style="min-height: 140px">
    <p>Do you want to set final expiry date for the version?</p>
    <mat-form-field class="col-12 expiry-picker" appearance="outline">
        <mat-label>Expiry date</mat-label>
        <input [(ngModel)]="newExpiry" [matDatepicker]="picker" matInput>
        <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
        <button (click)="newExpiry=''" *ngIf="newExpiry" aria-label="Clear" class="expiry-clear"
                mat-icon-button matSuffix>
            <mat-icon>close</mat-icon>
        </button>
        <mat-datepicker #picker [startAt]="minVersionExpiryDate"></mat-datepicker>
    </mat-form-field>
    <mat-checkbox [(ngModel)]="isSync">Sync with document</mat-checkbox>
</mat-dialog-content>
<mat-dialog-actions>
    <button color="primary" mat-button [mat-dialog-close]="{isSync: isSync, expiry: newExpiry}">Yes</button>
    <button mat-button mat-dialog-close>No</button>
</mat-dialog-actions>
