import { Component, OnInit } from '@angular/core';
import { BreadcrumbNavigationItem, BreadcrumbService } from '@app/core';
import { Observable } from 'rxjs';

@Component({
    selector: 'devfu-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {

    constructor(
        public breadcrumbService: BreadcrumbService) {
    }

    public getItems(): Observable<BreadcrumbNavigationItem[]> {
        return this.breadcrumbService.getCurrentPath();
    }

    ngOnInit() {
    }

}
