import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@app/core';
import { Log } from '@app/helpers';
import { UserSetting } from '@app/models';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CoreService } from '@app/services/core.service';

@Injectable({
    providedIn: 'root'
})
export class UserSettingService extends CoreService {

    settingsCache = new Map<number, UserSetting[]>();

    constructor(protected override auth: AuthService, protected http: HttpClient) {
        super('user_setting', auth);
        Log.c('User Setting Service Ready...');
    }

    getAll(useCache: boolean = true): Observable<UserSetting[]> {
        const user_id = this.auth.getIdentityFromStorage()?.id;
        return (useCache && user_id && this.settingsCache.has(user_id)) ? of(this.settingsCache.get(user_id))
            : this.http.get<UserSetting[]>(this.endpoint(), this.options).pipe(
                map(settings => {
                    const s = settings.map(UserSetting.assign);
                    this.settingsCache.set(user_id, s);
                    return s;
                })
            );
    }

    updateSettings(newSettings: UserSetting[]): Observable<UserSetting[]> {
        const user_id = this.auth.getIdentityFromStorage().id;
        this.settingsCache.delete(user_id);
        return this.http.put<UserSetting[]>(this.endpoint(), newSettings, this.options).pipe(
            map(settings => {
                const s = settings.map(UserSetting.assign);
                this.settingsCache.set(user_id, s);
                return s;
            })
        );
    }
}
