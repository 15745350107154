import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '@app/core';
import { Observable } from 'rxjs';
import { Log } from '@app/helpers';
import { map } from 'rxjs/operators';
import { AppSettingsService } from '@app/services/app-settings.service';
import { EulaService } from '@app/services/eula.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard {

    constructor(private router: Router,
                private authService: AuthService,
                private settingsService: AppSettingsService,
                private eulaService: EulaService) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>|Promise<boolean>|boolean {
        const loggedIn = this.authService.isLoggedIn();
        const url = state.url;

        Log.d('canActivate() - Logged in:', loggedIn, 'URL:', url);

        // If we open an email with a token link, log out and allow
        if (url.startsWith('/user/reset') || url.startsWith('/user/invitation') || url.startsWith('/user/verification')) {
            Log.d('Allowed - Token email link');
            this.authService.resetStorage();
            return true;
        }

        // Allow access to logged out pages if logged out
        if (url.startsWith('/sign-up') || url.startsWith('/login') || url.startsWith('/forgotten-password')) {
            if (loggedIn) {
                Log.d('Denied - Logged in');
                this.router.navigate(['/dashboard']).then();
                return false;
            }
            this.authService.resetStorage();
            if (url === '/sign-up') {
                return this.settingsService.getAll().pipe(map(s => {
                    if (!s.allow_sign_ups) {
                        Log.d('Denied - Sign-ups disabled');
                        this.authService.logout();
                        return false;
                    }
                    Log.d('Allowed - Sign-ups enabled & logged out');
                    return true;
                }));
            }
            Log.d('Allowed - Logged out');
            return true;
        }

        // Prevent logged-out users from accessing any other routes
        if (!loggedIn) {
            Log.d('Denied - Logged out');
            this.authService.redirectUrl = url;
            this.authService.logout();
            return false;
        }

        if (url === '/eula') {
            Log.d('Allowed - Logged in');
            return true;
        }

        return new Promise<boolean>((resolve, reject) => {
            // Check the EULA has been accepted
            this.eulaService.checkEulaAccepted().subscribe(eulaRead => {
                if (!eulaRead) {
                    Log.d('Denied - EULA not accepted');
                    this.authService.redirectUrl = url;
                    this.router.navigate(['/eula']).then();
                    resolve(false);
                } else {
                    // Check administrator for settings pages
                    if (url.startsWith('/settings')) {
                        this.authService.checkIsAdmin().subscribe((isAdmin: boolean) => {
                            if (!isAdmin) {
                                Log.d('Denied - Non-admin');
                                this.router.navigate(['/dashboard']).then();
                                resolve(false);
                            } else {
                                Log.d('Allowed - Global admin');
                                resolve(true);
                            }
                        });
                    } else {
                        // Check everyone can create or global editor when creating documents/atlases
                        if (url === '/document/create' || url === '/atlas/create') {
                            const isDocument = (url === '/document/create');
                            this.settingsService.getAll().subscribe(s => {
                                if ((isDocument && s.everyone_creates_documents) || (!isDocument && s.everyone_creates_atlases)) {
                                    Log.d(`Allowed - Everyone can create ${isDocument ? 'documents' : 'atlases'}`);
                                    resolve(true);
                                } else {
                                    this.authService.checkIsEditor().subscribe(isEditor => {
                                        if (isEditor) {
                                            Log.d('Allowed - Global editor');
                                            resolve(true);
                                        } else {
                                            Log.d('Denied - Not global editor');
                                            this.router.navigate(['/dashboard']).then();
                                            resolve(false);
                                        }
                                    });
                                }
                            });
                        } else {
                            Log.d('Allowed - Logged in & EULA accepted');
                            resolve(true);
                        }
                    }
                }
            });
        });
    }
}
