export class UserSetting {
    constructor(
        public key: string = '',
        public value: string = '',
        public int_value:  number = null,
        public ordinal: number = null
    ) {
    }

    public static assign(o: UserSetting) {
        return Object.assign(new UserSetting(), o);
    }
}
