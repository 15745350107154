import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AuthService } from '@app/core';
import { Log } from '@app/helpers';
import { DocumentVersion } from '@app/models';
import { map } from 'rxjs/operators';
import { CoreService } from '@app/services/core.service';

export class ImageBlobResponse {
    public constructor(
        public pageIdx: number,
        public imgBlob: Blob,
        public formatIdx: number = null) {
    }
}

@Injectable({
    providedIn: 'root'
})
export class ImageService extends CoreService {
    currentVersion: DocumentVersion;
    imageCache = new Map<string, ImageBlobResponse>();

    private targetPage = 0;
    private switchingDisplayMode = false;

    constructor(protected override auth: AuthService, protected http: HttpClient) {
        super('document', auth);
        Log.c('Image Service Ready...');
    }

    public setTargetPage(page: number) {
        this.targetPage = page;
    }

    public getTargetPage() {
        return this.targetPage;
    }

    public setSwitchingDisplayMode(isSwitching: boolean) {
        this.switchingDisplayMode = isSwitching;
    }

    public getSwitchingDisplayMode() {
        return this.switchingDisplayMode;
    }

    public setCurrentVersion(version: DocumentVersion) {
        this.currentVersion = version;
    }

    public getCurrentVersion() {
        return this.currentVersion;
    }

    public getImageUrl(documentID: number, formatID: number, pageNumber: number, format: string, size: number): string {
        return this.endpoint(documentID)
            + '/format/' + formatID
            + '/page/' + pageNumber
            + '/' + format
            + '/' + size
            + '?ct=' + Math.round(Math.random() * 100000);
    }

    public getImageBlob(pageIdx: number, documentId: number, formatId: number, pageNumber: number,
                        format: string, size: number, fileId: number, fileOrdinal: number): Observable<ImageBlobResponse> {
        const id = `${fileId}-${fileOrdinal}-${pageIdx}-${formatId}-${format}-${size}`;
        return this.imageCache.has(id) ? of(this.imageCache.get(id)) :
            this.http.get<Blob>(this.getImageUrl(documentId, formatId, pageNumber, format, size), { responseType: 'blob' as 'json' })
                .pipe(map(b => {
                        const imageBlob = new ImageBlobResponse(pageIdx, b, formatId);
                        this.imageCache.set(id, imageBlob);
                        return imageBlob;
                    })
                );
    }
}
