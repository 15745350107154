import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'titleCase'
})
export class TitleCasePipe implements PipeTransform {
    transform(value: string): string {
        if (!value) {
            return '';
        }
        const capitalisedWords = value.split(' ').map(word => {
            if (word.toLowerCase() === 'id') {
                return 'ID';
            }
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        });
        return capitalisedWords.join(' ');
    }
}
