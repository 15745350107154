<div class="col-lg-6 col-xl-5 col-md-8 mt-md-4 pt-md-5 mx-auto text-center">
    <img class="logo w-50 mx-auto" src="/assets/anthem_badge.png"/>
    <div class="col-lg-10 mt-4 mb-5 mx-auto">
        <h4>The specified resource does not exist or you do not have permission to access it.</h4>
        <p>Please contact an administrator for more assistance.</p>
        <p>
            <small><a [routerLink]="['/']">Click to return</a></small>
        </p>
    </div>
</div>
