import * as moment from 'moment-timezone';
import { Moment } from 'moment-timezone';
import { environment as env } from '@env/environment';
import { DocumentVersion, User } from '@app/models';


export class TransmissionLog {
    [key: string]: any; // Make the model string indexable, eg  w['surname']

    constructor(
        public id: number = null,
        public sender_id: number = null,
        public sent_time: string = null,
        public document_version_id: number = null,
        public target_name: string = null,
        public target_address: string = null,
        public sender: User = null,
        public document_version: DocumentVersion = null
    ) {
    }

    public static assign(o: TransmissionLog) {
        return Object.assign(new TransmissionLog(), o);
    }

    public getSentTime(): Moment {
        return moment(this.sent_time);
    }
}
