<div class="user-details-container">
    <mat-card appearance="outlined" *ngIf="user">
        <mat-card-title>
            {{ invitationID ? 'Complete Invitation - ' + email : (user.id ? 'User Details' : 'New User') }}
            @if (user.id) {
                - <span style="opacity: .7">{{ user.email }}</span>
            }
        </mat-card-title>
        <mat-card-content>
            <div class="p-4">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Name</mat-label>
                    <input [(ngModel)]="user.name" matInput>
                </mat-form-field>
                <mat-form-field class="w-100" appearance="outline" *ngIf="!user.id && !invitationID">
                    <mat-label>Email</mat-label>
                    <input [(ngModel)]="user.email" matInput type="email">
                </mat-form-field>
                <mat-form-field class="w-100" appearance="outline" *ngIf="!user.id && !invitationID">
                    <mat-label>User Groups</mat-label>
                    <mat-select [(ngModel)]="user.groups" multiple placeholder="No groups">
                        <mat-option *ngFor="let group of groups" [value]="group.id">{{ group.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                @if (!this.user.id) {
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Password</mat-label>
                        <input matInput [type]="hide ? 'password' : 'text'" [(ngModel)]="user.password">
                        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                                [attr.aria-pressed]="hide">
                            <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
                        </button>
                    </mat-form-field>
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Repeat Password</mat-label>
                        <input matInput [type]="hide ? 'password' : 'text'" [(ngModel)]="passwordRepeat">
                        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                                [attr.aria-pressed]="hide">
                            <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
                        </button>
                    </mat-form-field>
                }
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Timezone</mat-label>
                    <input type="text" [(ngModel)]="user.tz" (ngModelChange)="searchTimezone()" [matAutocomplete]="auto"
                           placeholder="Start typing to search..." (keydown.enter)="selectTimezone()"
                           (keydown.tab)="selectTimezone()" matInput>
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let tz of timezoneOptions" [value]="tz">
                            {{ tz.replace('_', ' ') }}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-hint *ngIf="!isTimezoneValid()">Invalid Timezone</mat-hint>
                </mat-form-field>
                <mat-checkbox [(ngModel)]="user.enabled" *ngIf="!invitationID">Enabled
                </mat-checkbox>
            </div>
        </mat-card-content>
        <mat-card-actions *ngIf="user">
            <button (click)="onCancel()" mat-button>Cancel</button>
            <button (click)="onSaveChanges()" [disabled]="!isValid()" color="primary"
                    mat-flat-button>{{ invitationID ? 'Sign Up' : (user.id !== 0 ? 'Save changes' : 'Create User') }}
            </button>
        </mat-card-actions>
    </mat-card>

    <mat-card appearance="outlined" *ngIf="user?.id && userGroups">
        <mat-card-title>
            User Groups
        </mat-card-title>
        <mat-card-content>
            <div class="px-3 py-2">
                <h4>Current User Groups</h4>
                @if (userGroups.length) {
                    @for (group of userGroups; track group.id) {
                        <div class="user-group-listing" [routerLink]="'/settings/security/group/' + group.id">
                            {{ group.name }}
                            <mat-icon (click)="$event.stopPropagation(); removeUserFromGroup(group)"
                                      matTooltip="Remove from group">cancel
                            </mat-icon>
                        </div>
                    }
                } @else {
                    <i>No groups</i>
                }
            </div>
            <mat-divider></mat-divider>

            <div class="px-3 py-2">
                <h4>Add Groups</h4>
                <mat-form-field class="w-100" appearance="outline" subscriptSizing="dynamic">
                    <mat-label>User Groups</mat-label>
                    <mat-select [(ngModel)]="selectedGroups" multiple placeholder="No groups"
                                [disabled]="!groupsCopy.length">
                        <mat-option *ngFor="let group of groupsCopy" [value]="group.id">{{ group.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="mt-2 d-flex align-items-center">
                    <button (click)="addUserToGroups()" [disabled]="selectedGroups.length === 0" color="primary"
                            class="me-2" mat-flat-button>Add to selected groups
                    </button>
                    <i *ngIf="userGroups?.length === groups?.length">This user is already a member of all groups.</i>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>
