import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '@app/core';
import { DocumentReview, RestResponseModel } from '@app/models';
import { CoreService } from '@app/services/core.service';
import { CacheHelper, Log } from '@app/helpers';

@Injectable({
    providedIn: 'root'
})
export class ReviewService extends CoreService {

    cache = new CacheHelper<DocumentReview>();

    constructor(protected override auth: AuthService, protected http: HttpClient) {
        super('document', auth);
        Log.c('Review Service Ready...');
    }

    getAllReviewsForDocument(documentID: number): Observable<DocumentReview[]> {
        return this.http.get<DocumentReview[]>(this.endpoint(`${documentID}/review`), this.options);
    }

    create(documentID: number, review: DocumentReview): Observable<DocumentReview> {
        this.cache.invalidateCollection();
        return this.http.post<DocumentReview>(this.endpoint(`${documentID}/review`), JSON.stringify(review), this.options);
    }

    delete(documentID: number, reviewID: number): Observable<RestResponseModel> {
        this.cache.invalidate(reviewID);
        return this.http.delete<RestResponseModel>(this.endpoint(`${documentID}/review/${reviewID}`), this.options);
    }
}
