import {
    Component,
    ElementRef,
    EmbeddedViewRef,
    HostListener,
    Injector,
    OnInit,
    ViewContainerRef
} from '@angular/core';
import { BasePortalOutlet, DomPortal, TemplatePortal } from '@angular/cdk/portal';
import { SearchResultsComponent } from '@app/components/pages/root';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { Router } from '@angular/router';
import { SearchService } from '@app/services/search.service';

@Component({
    selector: 'devfu-search-input',
    templateUrl: './search-input.component.html',
    styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnInit {

    searchQuery: string;

    // private searchResultPortalHost: DomPortalHost;

    private searchResultPortalOutlet: BasePortalOutlet;

    private componentPortal: TemplatePortal;

    private overlayRef: OverlayRef;

    private overlayViewRef: EmbeddedViewRef<any>;

    private sourceInput: HTMLInputElement;

    constructor(private elementRef: ElementRef,
                private injector: Injector,
                private viewContainerRef: ViewContainerRef,
                private overlay: Overlay,
                private searchService: SearchService,
                private router: Router) {

    }

    ngOnInit() {
        this.createContainerTemplate();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (this.sourceInput && this.overlayRef) {
            this.overlayRef.updateSize({ width: this.sourceInput.clientWidth + 'px' });
        }
    }

    checkClick() {

        if (this.sourceInput.value.length > 1) {
            this.show();
        }

    }

    show() {

        if (this.searchQuery && this.searchQuery.length > 1) {

            if (!this.overlayRef) {

                this.overlayRef = this.overlay.create({
                    hasBackdrop: true,
                    // height: "400px",
                    width: this.sourceInput.clientWidth + 'px',
                    positionStrategy: this.overlay
                        .position()
                        .flexibleConnectedTo(this.elementRef)
                        .withPositions([{
                            overlayX: 'start',
                            overlayY: 'top',
                            originX: 'start',
                            originY: 'bottom'
                        }]),
                    scrollStrategy: this.overlay.scrollStrategies.block()
                });

            }

            if (!this.overlayRef.hasAttached()) {

                this.overlayRef.backdropClick().subscribe(() => {
                    this.hide();
                });

                const resultsComponentRef = this.viewContainerRef.createComponent(SearchResultsComponent, { injector: this.injector });

                this.componentPortal = new TemplatePortal(
                    resultsComponentRef.instance.results,
                    this.viewContainerRef,
                    {
                        $implicit: this.searchQuery,
                        searchResults: this.searchService.getSearchResults(),
                        navigateToDocument: this.navigateToDocument.bind(this)
                    }
                );

                this.overlayViewRef = this.overlayRef.attach(this.componentPortal);

            }

            this.searchService.search(this.searchQuery);

        }
    }

    search(event$) {
        if (this.searchQuery && this.searchQuery.length > 1) {
            if (event$.keyCode === 27) {
                this.hide();
            } else {
                this.show();
            }
        } else {
            if (this.overlayRef) {
                this.hide();
            }
        }
    }

    hide() {
        // Write back the new value to the old input field and focus

        if (this.overlayRef) {
            this.overlayRef.detach();
            this.sourceInput.focus();
        }

        this.overlayRef = null;

    }

    navigateToDocument(id: number) {
        this.router.navigate(['/document', id]).then(() => {
            this.hide();
        });
        this.searchQuery = '';
    }


    private createContainerTemplate() {

        const searchResultPortal = new DomPortal((this.elementRef.nativeElement as HTMLElement).parentElement);

        searchResultPortal.setAttachedHost(this.searchResultPortalOutlet);

        this.sourceInput = (this.elementRef.nativeElement as HTMLElement).getElementsByTagName('input').item(0);

    }

}
