import {Category} from '@app/models/atlas.model';

export interface DocumentAtlas {
    path: string[];
    category: Category;
}

export interface DocumentCategory {
    id: number;
    document_id: number;
    category_id: number;
    ordinal: number;
}
