import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '@app/core';
import { CacheHelper, Log } from '@app/helpers';
import { RestResponseModel, Tag, TagValue } from '@app/models';
import { map } from 'rxjs/operators';
import { CoreService } from '@app/services/core.service';

@Injectable({
    providedIn: 'root'
})
export class TagService extends CoreService {

    tagCache = new CacheHelper<Tag>();
    tagValueCache = new CacheHelper<TagValue[]>();

    constructor(protected override auth: AuthService, protected http: HttpClient) {
        super('tag', auth);
        Log.c('Tag Service Ready...');
    }

    getAll(): Observable<Tag[]> {
        return this.tagCache.returnAll(this.http.get<Tag[]>(this.endpoint(), this.options).pipe(
            map(tags => tags.map(Tag.assign))
        ));
    }

    create(tag: Tag): Observable<Tag> {
        this.tagCache.invalidateCollection();
        return this.http.post<Tag>(this.endpoint(), JSON.stringify(tag), this.options).pipe(map(Tag.assign));
    }

    update(tag: Tag): Observable<Tag> {
        this.tagCache.invalidate(tag.id);
        return this.tagCache.return(tag.id,
            this.http.put<Tag>(this.endpoint(tag.id), JSON.stringify(tag), this.options).pipe(map(Tag.assign))
        );
    }

    delete(tagID: number): Observable<RestResponseModel> {
        this.tagCache.invalidate(tagID);
        return this.http.delete<RestResponseModel>(this.endpoint(tagID), this.options);
    }

    public getValues(tagID: number): Observable<TagValue[]> {
        return this.tagValueCache.return(tagID,
            this.http.get<TagValue[]>(this.endpoint(`${tagID}/value`)).pipe(
                map(values => values.map(v => TagValue.assign(v))))
        );
    }

    public setValues(tagID: number, tagValues: TagValue[]): Observable<TagValue[]> {
        this.tagValueCache.invalidate(tagID);
        return this.tagValueCache.return(tagID,
            this.http.post<TagValue[]>(this.endpoint(`${tagID}/value`), tagValues, this.options).pipe(
                map(values => values.map(v => TagValue.assign(v))))
        );
    }
}
