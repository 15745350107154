<div class="col-lg-6 col-xl-5 col-md-8 mt-md-4 pt-md-5 mx-auto text-center">
    <img class="logo w-50 mx-auto" src="/assets/anthem_badge.png"/>
    <div class="row">
        <div class="col-lg-10 mt-4 mx-auto" style="max-width: 90%;" *ngIf="isValid">
            <p>
                <small>
                    Your email address has been verified.
                    <br/>
                    An administrator will review your sign-up request.
                </small>
            </p>
        </div>
        <div class="col-lg-10 mx-auto">
            <button class="my-3 mx-auto mat-elevation-z0 w-25" color="primary" routerLink="" mat-flat-button>
                <span>Return</span>
            </button>
        </div>
    </div>
</div>
