import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Log } from '@app/helpers';
import { UserService } from '@app/services/user.service';
import { MessageService } from '@app/services/message.service';
import { SecurityTokenService } from '@app/services/security-token.service';
import { AuthService } from '@app/core';

@Component({
    selector: 'devfu-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
    email: string;
    token: string;
    isValid = false;
    newPass: string = '';
    confirmPass: string = '';
    hideNew = true;
    hideConfirm = true;

    constructor(private route: ActivatedRoute,
                private userService: UserService,
                private messageService: MessageService,
                private tokenService: SecurityTokenService,
                private authService: AuthService) {
    }

    ngOnInit(): void {
        this.email = this.route.snapshot.queryParamMap.get('email');
        this.token = this.route.snapshot.queryParamMap.get('token');
        this.isSessionValid();
    }

    isSessionValid() {
        this.tokenService.checkPasswordResetToken(this.email, this.token).subscribe({
            next: () => {
                Log.d('Security token is valid');
                this.isValid = true;
            },
            error: () => {
                this.isValid = false;
                this.messageService.showMessage({
                    'title': 'Invalid token',
                    'message': 'The provided token is invalid',
                    duration: 3000,
                    icon: 'fas fa-times-circle'
                });
                Log.d('Security token not valid');
                this.authService.logout();
            }
        });
    }

    onReset() {
        // check if new password and confirm pass are the same
        if (this.isValid && this.newPass.length && this.newPass === this.confirmPass) {
            // send post request to server
            this.userService.resetPassword(this.email, this.newPass, this.token)
                .subscribe(() => {
                    Log.d(this.email, 'user password updated');
                    // show success message
                    this.messageService.showMessage({
                        'title': 'User updated',
                        'message': 'User information updated successfully',
                        duration: 3000,
                        icon: 'fas fa-check-circle'
                    });
                    this.authService.logout();
                });
        }
    }

}
