import { Pipe, PipeTransform } from '@angular/core';
import { environment as env } from '@env/environment';

@Pipe({
    name: 'dateChip'
})
export class DateChipPipe implements PipeTransform {
    transform(value: Date, format: string = env.notificationFormat): string {
        const currentDate = new Date();
        const day = value.getDate();
        const month = value.getMonth() + 1;
        const year = value.getFullYear();
        const hours = value.getHours();
        const minutes = value.getMinutes();

        if (currentDate.getFullYear() === year) {
            format = format.replace(/Y+\//g, '').replace(/\/Y+/g, '');
        }

        // Replace placeholders in the format string with corresponding values
        const formattedString = format
            .replace('D', day.toString())
            .replace('MM', month < 10 ? '0' + month.toString() : month.toString())
            .replace('YY', year.toString().slice(-2))
            .replace('H', hours.toString())
            .replace('mm', `${minutes < 10 ? '0' : ''}${minutes}`);

        if (currentDate.getFullYear() === year && currentDate.getMonth() === value.getMonth() && currentDate.getDate() === day) {
            // If today, return only time
            return formattedString.split(' ')[1];
        } else {
            // Otherwise, return full date
            return formattedString.split(' ')[0];
        }
    }
}
