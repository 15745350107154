import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '@app/core';
import { Log } from '@app/helpers';
import { map } from 'rxjs/operators';
import { RestResponseModel, ReviewRequest } from '@app/models';
import { CoreService } from '@app/services/core.service';

@Injectable({
    providedIn: 'root'
})
export class ReviewRequestService extends CoreService {

    constructor(protected override auth: AuthService, protected http: HttpClient) {
        super('review_request', auth);
        Log.c('Review Request Service Ready...');
    }

    getAll(): Observable<ReviewRequest[]> {
        return this.http.get<ReviewRequest[]>(this.endpoint(), this.options).pipe(
            map(reviews => reviews.map(ReviewRequest.assign))
        );
    }

    getOpenForUser(): Observable<ReviewRequest[]> {
        return this.http.get<ReviewRequest[]>(this.endpoint('user'), this.options).pipe(
            map(reviews => reviews.map(ReviewRequest.assign))
        );
    }

    createBulkRequests(request: ReviewRequest, reviewerIDs: number[]): Observable<RestResponseModel> {
        return this.http.post<RestResponseModel>(this.endpoint('bulk'), { 'request': request, 'reviewer_ids': reviewerIDs }, this.options);
    }

    delete(requestID: number): Observable<RestResponseModel> {
        return this.http.delete<RestResponseModel>(this.endpoint(requestID), this.options);
    }
}
