import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@app/core';
import { AuthGroup, AuthGroupIdentity, RestResponseModel } from '@app/models';
import { lastValueFrom, Observable } from 'rxjs';
import { CoreService } from '@app/services/core.service';
import { Log } from '@app/helpers';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class GroupService extends CoreService {

    constructor(protected override auth: AuthService, protected http: HttpClient) {
        super('group', auth);
        Log.c('Group Service Ready...');
    }

    getAll(): Observable<AuthGroup[]> {
        return this.http.get<AuthGroup[]>(this.endpoint(), this.options);
    }

    get(groupID: number): Observable<AuthGroup> {
        return this.http.get<AuthGroup>(this.endpoint(groupID), this.options);
    }

    create(group: AuthGroup): Observable<AuthGroup> {
        return this.http.post<AuthGroup>(this.endpoint(), JSON.stringify(group), this.options);
    }

    update(group: AuthGroup): Observable<AuthGroup> {
        return this.http.put<AuthGroup>(this.endpoint(group.id), JSON.stringify(group), this.options);
    }

    delete(groupID: number): Observable<RestResponseModel> {
        return this.http.delete<RestResponseModel>(this.endpoint(groupID), this.options);
    }

    public addUserToGroup(groupID: number, userID: number): Promise<AuthGroupIdentity> {
        if (userID === this.auth.getIdentityFromStorage().id) {
            this.auth.clearObservables();
        }
        const newGroupUser = new AuthGroupIdentity(groupID, userID);
        return lastValueFrom(
            this.http.post<AuthGroupIdentity>(this.endpoint(`${groupID}/user`), newGroupUser, this.options).pipe(
                tap(() => {
                    if (userID === this.auth.getIdentityFromStorage().id) {
                        Log.d('Updating self');
                        this.auth.clearObservables();
                        this.auth.onStateUpdated.emit();
                    }
                })
            )
        );
    }

    public removeUserFromGroup(groupID: number, userID: number): Promise<RestResponseModel> {
        return lastValueFrom(
            this.http.delete<RestResponseModel>(this.endpoint(`${groupID}/user/${userID}`), this.options).pipe(
                tap(() => {
                    if (userID === this.auth.getIdentityFromStorage().id) {
                        Log.d('Updating self. Resetting auth checks.');
                        this.auth.clearObservables();
                        this.auth.onStateUpdated.emit();
                    }
                })
            )
        );
    }
}
