export class EulaModel {
    [key: string]: any; // Make the model string indexable, eg  w['surname']

    constructor(public id: number = null,
                public html: string = null,
                public created_time: string = null,
                public active: boolean = false,
    ) {
    }
}
