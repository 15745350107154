import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UserGroupCombo} from '@app/models/user-group-combo.model';
import {GroupService} from '@app/services/group.service';
import {forkJoin} from 'rxjs';
import {Log} from '@app/helpers/log.helper';
import {FormControl, Validators} from '@angular/forms';
import { UserService } from '@app/services/user.service';

@Component({
    selector: 'devfu-user-group-combo',
    templateUrl: './user-group-combo.component.html',
    styleUrl: './user-group-combo.component.scss'
})
export class UserGroupComboComponent implements OnInit {

    @Output() selectionChanged = new EventEmitter<UserGroupCombo>();
    usersAndGroups: UserGroupCombo[] = [];
    shownUsersAndGroups: UserGroupCombo[] = [];

    @Input()
    existingEntries: UserGroupCombo[] = [];
    selectedItem: UserGroupCombo | null = null;

    formControl: FormControl = new FormControl('', [Validators.required]);

    constructor(private userService: UserService,
                private groupService: GroupService,
    ) {

    }

    ngOnInit() {
        const userSub = this.userService.getAll();
        const groupSub = this.groupService.getAll();

        forkJoin({users: userSub, groups: groupSub})
            .subscribe(data => {
                const filteredUsers = data.users.filter(u => !u.is_pending);
                this.usersAndGroups = [
                    ...filteredUsers.map(u => UserGroupCombo.mapFromUser(u)),
                    ...data.groups.map(g => UserGroupCombo.mapFromGroup(g))
                ];

                this.shownUsersAndGroups = this.usersAndGroups;
            });

        this.formControl.valueChanges.subscribe(change => {
            if (typeof change === 'string') {
                const lc = change.toLowerCase();
                this.shownUsersAndGroups = this.usersAndGroups.filter(
                    ug => ug.user_name.toLowerCase().indexOf(lc) !== -1 ||
                        ug.group_name.toLowerCase().indexOf(lc) !== -1);
                this.selectedItem = null;
                this.selectionChanged.emit(null);
            } else {
                this.selectedItem = change;
                this.shownUsersAndGroups = this.usersAndGroups;
                this.selectionChanged.emit(change);
            }

            Log.d(change);
        });
    }

    displayText(obj: UserGroupCombo): string {
        if (!obj) {
            return '';
        }

        return obj.user_name || obj.group_name;
    }
}
