<mat-form-field appearance="outline" class="w-100">
    <mat-label>
        @if (selectedItem?.user_id) {
            User
        } @else if (selectedItem?.group_id) {
            Group
        } @else {
            User / Group
        }
    </mat-label>
    <input type="text"
           matInput
           [formControl]="formControl"
           [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayText.bind(this)">
        <mat-option *ngFor="let ug of shownUsersAndGroups" [value]="ug">
            @if (ug.user_id){
                <mat-icon>person</mat-icon>{{ ug.user_name}}
            } @else if (ug.group_id){
                <mat-icon>group</mat-icon>{{ ug.group_name}}
            }
        </mat-option>
        <mat-option *ngIf="!usersAndGroups.length" disabled="1">No Users or Groups Available</mat-option>
    </mat-autocomplete>
</mat-form-field>
