import { Injectable } from '@angular/core';
import { BreadcrumbNavigationItem } from '@app/core';
import { BehaviorSubject, from, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class BreadcrumbService {

    private readonly currentPath: BehaviorSubject<BreadcrumbNavigationItem[]>;

    constructor() {
        this.currentPath = new BehaviorSubject<BreadcrumbNavigationItem[]>([]);
    }

    public getCurrentPath(): Observable<BreadcrumbNavigationItem[]> {
        return from(this.currentPath);
    }

    public setCurrentPath(items: BreadcrumbNavigationItem[]) {
        this.currentPath.next(items);
    }

}
