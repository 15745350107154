@if (isAuthenticated) {
    <div class="row dash-container">
        <div class="col-lg-3 d-md-none d-sm-none d-none d-lg-block offset-1 text-center">
            <img class="logo mx-auto" src="/assets/anthem.png"/>
            <div>
                <small class="version">Version {{ version }}</small>
            </div>
            <mat-divider class="my-3"></mat-divider>
            <!--<a mat-button color="primary" class="w-100" [routerLink]="['/atlas',firstAtlasId]" *ngIf="firstAtlasId">View-->
            <!--Atlas</a>-->
            @if (canCreateDocument) {
                <a class="w-100" color="primary" mat-button routerLink="/document/create">
                    <mat-icon>note_add</mat-icon>
                    Add New Document</a>
                <br/>
            }
            <a class="w-100" color="primary" mat-button routerLink="/report">
                <mat-icon>table_view</mat-icon>
                Reporting</a>
            <a class="w-100" color="primary" mat-button routerLink="/atlas">
                <mat-icon>language</mat-icon>
                Manage Atlases</a>
            <mat-divider *ngIf="privateAtlases.length > 0" class="my-3"></mat-divider>
            <div *ngIf="privateAtlases.length > 0">
                <h5>Private Atlases</h5>
                <a *ngFor="let atlas of privateAtlases" [routerLink]="['/atlas',atlas.id]"
                   class="w-100 d-md-flex" color="primary" mat-button>
                    {{ atlas.name }} </a>

                <a *ngFor="let atlas of privateAtlases" [routerLink]="['/atlas/mobile',atlas.id]"
                   class="w-100 d-md-none" color="primary"
                   mat-button>
                    {{ atlas.name }} </a>

            </div>
            <mat-divider *ngIf="publicAtlases.length > 0" class="my-3"></mat-divider>
            <div *ngIf="publicAtlases.length > 0">
                <h5>Public Atlases</h5>
                <a *ngFor="let atlas of publicAtlases" [routerLink]="['/atlas',atlas.id]"
                   class="w-100 d-none d-md-flex"
                   color="primary" mat-button>
                    {{ atlas.name }} </a>
                <a *ngFor="let atlas of publicAtlases" [routerLink]="['/atlas/mobile',atlas.id]"
                   class="w-100 d-inline-block d-md-none"
                   color="primary" mat-button>
                    {{ atlas.name }} </a>

            </div>
        </div>

        <!-- md nav view -->

        <div class="col-md-11 d-md-inline-flex align-items-center d-none d-lg-none d-xl-none mx-auto">
            @if (canCreateDocument) {
                <a color="primary" mat-button routerLink="/document/create">Add New Document</a>
            }
            <!-- TODO: Create mobile-friendly reporting page -->
            <!--<a color="primary" mat-button routerLink="/report">Reporting</a>-->
            <mat-form-field class="float-right" style="margin-left: auto" appearance="outline">
                <mat-select placeholder="Atlases">
                    <mat-option routerLink="/atlas">
                        <mat-icon>language</mat-icon>
                        Manage Atlases
                    </mat-option>
                    <mat-optgroup *ngIf="privateAtlases.length > 0" label="Private Atlases">
                        <mat-option *ngFor="let atlas of privateAtlases" [matTooltip]="'View ' + atlas.name"
                                    [routerLink]="['/atlas',atlas.id]" [value]="atlas.id"
                                    class="d-none d-md-inline-block">
                            {{ atlas.name }}
                        </mat-option>
                        <mat-option *ngFor="let atlas of privateAtlases" [matTooltip]="'View ' + atlas.name"
                                    [routerLink]="['/atlas',atlas.id]" [value]="atlas.id"
                                    class="d-inline-block d-md-none">
                            {{ atlas.name }}
                        </mat-option>
                    </mat-optgroup>
                    <mat-optgroup *ngIf="publicAtlases.length > 0" label="Public Atlases">
                        <mat-option *ngFor="let atlas of publicAtlases" [matTooltip]="'View ' + atlas.name"
                                    [routerLink]="['/atlas',atlas.id]" [value]="atlas.id"
                                    class="d-none d-md-inline-block">
                            {{ atlas.name }}
                        </mat-option>
                        <mat-option *ngFor="let atlas of publicAtlases" [matTooltip]="'View ' + atlas.name"
                                    [routerLink]="['/atlas',atlas.id]" [value]="atlas.id"
                                    class="d-inline-block d-md-none">
                            {{ atlas.name }}
                        </mat-option>
                    </mat-optgroup>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col-lg-7 col-md-11 d-none d-md-block d-lg-block mx-auto">
            <mat-card appearance="outlined"
                      *ngIf="reviewRequestsState != LoadingState.LoadedNoData; else noReviewRequests">
                <mat-card-title>
                    Open Review Requests {{ reviewRequests?.length ? '(' + reviewRequests.length + ')' : '' }}
                    <a class="table-header-button" mat-button routerLink="/document/review-requests">
                        All Requests
                    </a>
                </mat-card-title>
                <mat-card-content [hidden]="reviewRequestsState != !!LoadingState.LoadedOK">
                    <mat-table #reviewRequestsSort="matSort" [dataSource]="reviewRequestsDataSource" matSort>
                        <ng-container matColumnDef="title">
                            <mat-header-cell *matHeaderCellDef mat-sort-header="document_title">Title</mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <span class="document-title">
                                    {{ element.document_title }}
                                    <span *ngIf="element.version_status==='DRAFT'"
                                          class="draft-document"> (draft)</span>
                                </span>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="version_title">
                            <mat-header-cell *matHeaderCellDef mat-sort-header="document_version">Version
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                {{ element.document_version_title }}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="type">
                            <mat-header-cell *matHeaderCellDef mat-sort-header="type">Type</mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                {{ element.review_type }}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="request_time">
                            <mat-header-cell *matHeaderCellDef mat-sort-header="request_time">Requested At
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                {{ element.getCreatedTime().format(dateFormat) }}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="requestor">
                            <mat-header-cell *matHeaderCellDef mat-sort-header="requestor_name">Requested By
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                {{ element.requestor_name }}
                            </mat-cell>
                        </ng-container>


                        <mat-header-row *matHeaderRowDef="reviewRequestsDisplayedColumns"></mat-header-row>
                        <mat-row (click)="viewReviewRequest(row.id, row.document_id)"
                                 *matRowDef="let row; columns: reviewRequestsDisplayedColumns;"></mat-row>
                    </mat-table>
                </mat-card-content>
                <ng-container *ngIf="reviewRequestsState == LoadingState.Loading">
                    <ng-container *ngTemplateOutlet="loadingDocs"></ng-container>
                </ng-container>
                <ng-container *ngIf="reviewRequestsState == LoadingState.ErrorLoading">
                    <ng-container *ngTemplateOutlet="docsError"></ng-container>
                </ng-container>
            </mat-card>

            <ng-template #noReviewRequests>
                <mat-card>
                    <mat-card-title class="justify-content-between">
                        <span>
                            <mat-icon style="vertical-align: text-bottom">info_outline</mat-icon>
                            No open review requests
                        </span>
                        <a class="table-header-button" mat-button routerLink="/document/review-requests">
                            All Requests
                        </a>
                    </mat-card-title>
                </mat-card>
            </ng-template>

            <mat-card appearance="outlined">
                <mat-card-title>New and Updated Documents</mat-card-title>
                <mat-card-content [hidden]="!newDocumentsState == !!LoadingState.LoadedOK">
                    <mat-table #newDocumentsSort="matSort" [dataSource]="newDocumentsDataSource" matSort>
                        <ng-container matColumnDef="title">
                            <mat-header-cell *matHeaderCellDef mat-sort-header="title">Title</mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <span class="document-title">
                                    {{ element.title }}
                                    <span *ngIf="element.status==='DRAFT'" class="draft-document"> (draft)</span>
                                </span>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="version_format">
                            <mat-header-cell *matHeaderCellDef>Format</mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <span *ngIf="element.latest_version">{{ element.latest_version }}</span>
                                <span *ngIf="element.format_name">({{ element.format_name }})</span>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="date_added">
                            <mat-header-cell *matHeaderCellDef mat-sort-header="ctime">Date Added</mat-header-cell>
                            <mat-cell
                                *matCellDef="let element"> {{ element.getCreatedTime().format(dateFormat) }}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="upload_user">
                            <mat-header-cell *matHeaderCellDef mat-sort-header="upload_user">Uploaded By
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <span *ngIf="element.upload_user">{{ element.upload_user }}</span>
                            </mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="newDocumentsDisplayedColumns"></mat-header-row>
                        <mat-row (click)="viewDocument(row.id)"
                                 *matRowDef="let row; columns: newDocumentsDisplayedColumns;"></mat-row>
                    </mat-table>
                </mat-card-content>
                <ng-container *ngIf="newDocumentsState == LoadingState.Loading">
                    <ng-container *ngTemplateOutlet="loadingDocs"></ng-container>
                </ng-container>
                <ng-container *ngIf="newDocumentsState == LoadingState.LoadedNoData">
                    <ng-container *ngTemplateOutlet="noDocsLoaded"></ng-container>
                </ng-container>
                <ng-container *ngIf="newDocumentsState == LoadingState.ErrorLoading">
                    <ng-container *ngTemplateOutlet="docsError"></ng-container>
                </ng-container>
            </mat-card>

            <mat-card appearance="outlined">
                <mat-card-title>
                    Documents To Be Revised
                    <a class="table-header-button" mat-button routerLink="/document/expiring">
                        All Expirations
                    </a>
                </mat-card-title>
                <mat-card-content [hidden]="!revisionDocumentState == !!LoadingState.LoadedOK">
                    <mat-table #revisionNeededSort="matSort" [dataSource]="revisionNeededDataSource" matSort>
                        <ng-container matColumnDef="title">
                            <mat-header-cell *matHeaderCellDef mat-sort-header="title"> Title</mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <span class="document-title">
                                    {{ element.title }}
                                    <span *ngIf="element.status==='DRAFT'" class="draft-document"> (draft)</span>
                                </span>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="version_format">
                            <mat-header-cell *matHeaderCellDef mat-sort-header="version_format">Format</mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <span *ngIf="element.latest_version">{{ element.latest_version }}</span>
                                <span *ngIf="element.format_name">({{ element.format_name }})</span>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="revision_due">
                            <mat-header-cell *matHeaderCellDef mat-sort-header="expiry"> Revision Due</mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <span
                                    [ngClass]="isExpired(element.getDashboardExpirationTime()) ? 'expired' : ''">{{ element.getDashboardExpirationTime().format(revisionDateFormat) }}</span>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="owner">
                            <mat-header-cell *matHeaderCellDef mat-sort-header="owner_name">Owner</mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <span *ngIf="element.owner_name">{{ element.owner_name }}</span>
                            </mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="revisionNeededDisplayedColumns"></mat-header-row>
                        <mat-row (click)="viewDocument(row.id)"
                                 *matRowDef="let row; columns: revisionNeededDisplayedColumns;"></mat-row>
                    </mat-table>
                    <ng-container *ngIf="revisionDocumentState == LoadingState.Loading">
                        <ng-container *ngTemplateOutlet="loadingDocs"></ng-container>
                    </ng-container>
                    <ng-container *ngIf="revisionDocumentState == LoadingState.LoadedNoData">
                        <ng-container *ngTemplateOutlet="noDocsRevision"></ng-container>
                    </ng-container>
                    <ng-container *ngIf="revisionDocumentState == LoadingState.ErrorLoading">
                        <ng-container *ngTemplateOutlet="docsError"></ng-container>
                    </ng-container>
                </mat-card-content>
            </mat-card>
        </div>

        <div
            class="d-md-none px-4 col-xs-12 col-sm-12 d-xs-inline-flex d-sm-inline-flex flex-column align-items-center d-md-none d-xl-none mx-auto">
            <mat-card appearance="outlined" class="w-100">
                <mat-card-title>
                    Open Review Requests
                    <a class="table-header-button" mat-button routerLink="/document/review-requests">
                        All Requests
                    </a>
                </mat-card-title>
                <mat-card-content>
                    <mat-table #reviewRequestsSort="matSort" [dataSource]="reviewRequestsDataSource"
                               class="small-requests-table" matSort>
                        <ng-container matColumnDef="title">
                            <mat-header-cell *matHeaderCellDef mat-sort-header="title"> Title</mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <span class="document-title">
                                    {{ element.document_title }}
                                    <span class="draft-document">
                                        {{ element.document_version_title }}
                                        @if (element.version_status === 'DRAFT') {
                                            (draft)
                                        }
                                    </span>
                                </span>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="revision_due">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> Requested At</mat-header-cell>
                            <mat-cell *matCellDef="let element"></mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="type">
                            <mat-header-cell *matHeaderCellDef class="justify-content-end" mat-sort-header="">
                                Requested At<br/>Type
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="d-flex flex-column align-items-end text-right">
                                <div>
                                    {{ element.getCreatedTime().format(revisionDateFormat) }}
                                </div>
                                <div class="font-weight-light">{{ element.review_type }}</div>
                            </mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="smallReviewRequestsDisplayedColumns"></mat-header-row>
                        <mat-row (click)="viewDocument(row.id)"
                                 *matRowDef="let row; columns: smallReviewRequestsDisplayedColumns;"></mat-row>
                    </mat-table>
                </mat-card-content>
            </mat-card>

            <mat-card appearance="outlined" class="w-100">
                <mat-card-title>
                    New and Updated Documents
                </mat-card-title>
                <mat-card-content>
                    <mat-table #newDocumentsSort="matSort" [dataSource]="newDocumentsDataSource"
                               class="small-new-docs-table"
                               matSort>

                        <ng-container matColumnDef="title">
                            <mat-header-cell *matHeaderCellDef mat-sort-header="title"> Title</mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <span class="document-title">
                                    {{ element.title }}
                                    <span *ngIf="element.status==='DRAFT'" class="draft-document"> (draft)</span>
                                </span>
                            </mat-cell>
                        </ng-container>

                        <!--<ng-container matColumnDef="date_added">-->
                        <!--<mat-header-cell *matHeaderCellDef mat-sort-header> Date Added / Added by</mat-header-cell>-->
                        <!---->

                        <!--<mat-cell *matCellDef="let element"> {{element.getCreatedTime().format(dateFormat)}}</mat-cell>-->
                        <!--</ng-container>-->
                        <!---->
                        <ng-container matColumnDef="upload_user_new">
                            <mat-header-cell *matHeaderCellDef class="justify-content-end" mat-sort-header="">Date Added<br/>
                                Uploaded By
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="d-flex flex-column align-items-end text-right">
                                <div>{{ element.getCreatedTime().format(dateFormat) }}</div>
                                <div *ngIf="element.upload_user"
                                     class="font-weight-light">{{ element.upload_user }}
                                </div>
                            </mat-cell>
                        </ng-container>
                        <!---->
                        <!--<ng-container matColumnDef="owner">-->
                        <!--<mat-header-cell *matHeaderCellDef mat-sort-header> Added By</mat-header-cell>-->
                        <!--<mat-cell *matCellDef="let element">-->
                        <!--<span *ngIf="element.owner_name">{{ element.owner_name }}</span>              </mat-cell>-->
                        <!--</ng-container>-->

                        <mat-header-row *matHeaderRowDef="smallNewDocumentsDisplayedColumns"></mat-header-row>
                        <mat-row (click)="viewDocument(row.id)"
                                 *matRowDef="let row; columns: smallNewDocumentsDisplayedColumns;"></mat-row>
                    </mat-table>
                </mat-card-content>
            </mat-card>


            <mat-card appearance="outlined" class="w-100">
                <mat-card-title>
                    Documents To Be Revised
                    <a class="table-header-button" mat-button routerLink="/document/expiring">
                        All Expirations
                    </a>
                </mat-card-title>
                <mat-card-content>
                    <mat-table #revisionNeededSort="matSort" [dataSource]="revisionNeededDataSource"
                               class="small-revision-table" matSort>
                        <ng-container matColumnDef="title">
                            <mat-header-cell *matHeaderCellDef mat-sort-header="title"> Title</mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <span class="document-title">
                                    {{ element.title }}
                                    <span *ngIf="element.status==='DRAFT'" class="draft-document"> (draft)</span>
                                </span>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="revision_due">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> Revision Due</mat-header-cell>
                            <mat-cell *matCellDef="let element"></mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="owner">
                            <mat-header-cell *matHeaderCellDef class="justify-content-end" mat-sort-header="">Revision
                                Due<br/>Owner
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="d-flex flex-column align-items-end text-right">
                                <div
                                    [ngClass]="isExpired(element.getDashboardExpirationTime()) ? 'expired' : ''">{{ element.getDashboardExpirationTime().format(revisionDateFormat) }}
                                </div>
                                <div *ngIf="element.owner_name" class="font-weight-light">{{ element.owner_name }}</div>
                            </mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="smallRevisionNeededDisplayedColumns"></mat-header-row>
                        <mat-row (click)="viewDocument(row.id)"
                                 *matRowDef="let row; columns: smallRevisionNeededDisplayedColumns;"></mat-row>
                    </mat-table>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
}

<ng-template #loadingDocs>
    <mat-card-content>
        <div class="large-table-content">
            <img src="/anthem.svg"
                 style="filter: opacity(50%) grayscale(50%); width: 100px; animation: pulse 2s linear infinite;">
            <span style="font-size: 30px; margin-left: 25px;">Loading...</span>
        </div>
    </mat-card-content>
</ng-template>

<ng-template #noDocsLoaded>
    <mat-card-content>
        <div class="large-table-content" style="cursor: pointer" [routerLink]="['/document/create']">
            No Documents<br>
            Click here to add your document to Anthem
        </div>
    </mat-card-content>
</ng-template>

<ng-template #noDocsRevision>
    <mat-card-content>
        <div class="large-table-content">
            No Documents<br>
            All documents are up-to-date
        </div>
    </mat-card-content>
</ng-template>

<ng-template #docsError>
    <mat-card-content>
        <div class="large-table-content">
            <img src="/assets/svg/sad.svg" style="filter: opacity(30%); width: 100px; ">
            <span style="font-size: 30px; margin-left: 25px;">Unable to load content<br>You may be offline or experiencing internet issues</span>
        </div>
    </mat-card-content>
</ng-template>
