<mat-toolbar color="primary" style="padding-right: 8px !important;">
    <button (click)="mobileSearchVisible = false" *ngIf="isAuthenticated && mobileSearchVisible" class="d-lg-none"
            mat-icon-button>
        <mat-icon>chevron_left</mat-icon>
    </button>
    <button (click)="sidenav.open()" *ngIf="isAuthenticated && !mobileSearchVisible" class="d-lg-none" mat-icon-button>
        <mat-icon>menu</mat-icon>
    </button>

    <span [ngClass]="{'d-none d-sm-block': mobileSearchVisible,'d-lg-none d-xl-none': !mobileSearchVisible}"
          class="branding"
          routerLink="/dashboard">
        Anthem
    </span>
    <span class="branding d-none d-md-none d-lg-block d-lg-flex col-lg-4" routerLink="/dashboard">
    <img src="/assets/anthem_logo.png"/> Anthem Document Management System</span>
    @if (isAuthenticated) {
        <devfu-search-input
            [ngClass]="{'d-none d-sm-block d-md-block': mobileSearchVisible,'d-none d-lg-block': !mobileSearchVisible}"
            class="col"></devfu-search-input>

        <span class="d-none d-lg-block">
        @for (item of navigation; track item.link) {
            <button *ngIf="!item.admin || isAdmin" [matTooltip]="item.label"
                    [routerLink]="[item.link]"
                    class="nav-button" mat-mini-fab
                    routerLinkActive="active">
            <i [ngClass]="item.icon"></i>
        </button>
        }
    </span>

        <div *ngIf="!mobileSearchVisible" class="d-flex d-lg-none justify-content-end w-100">
            <button (click)="mobileSearchVisible = true" class="hidden-sm-down" mat-icon-button>
                <mat-icon>search</mat-icon>
            </button>
            <button class="hidden-sm-down" mat-icon-button routerLink="/document/create">
                <mat-icon>note_add</mat-icon>
            </button>
            <button (click)="openAtlasMenu()" class="hidden-sm-down" mat-icon-button>
                <mat-icon>language</mat-icon>
            </button>
        </div>
        <div *ngIf="mobileSearchVisible" class="d-flex d-lg-none justify-content-end">
            <button (click)="openAdvancedSearch()" class="hidden-sm-down" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
            </button>
        </div>

        <button [matMenuTriggerFor]="toolbarUserMenu"
                class="user-button float-right ms-2 d-none d-lg-block"
                mat-stroked-button>
            <mat-icon>person</mat-icon>
            <span *ngIf="identity" class="username-display ms-2">{{ identity?.name }}</span>
        </button>

        <!-- sm, xs views -->

        <mat-menu #toolbarUserMenu="matMenu">
            <button (click)="onUserClick()" class="menu-fullname-display" mat-menu-item>
                <mat-icon>person</mat-icon>
                {{ identity?.name }}
            </button>
            <mat-divider></mat-divider>
            <button (click)="onLogoutClick()" mat-menu-item>
                <mat-icon>power_settings_new</mat-icon>
                <span>Logout</span>
            </button>
        </mat-menu>

        <button id="notification-button" matTooltip="Notifications" mat-mini-fab
                (click)="showNotifications = !showNotifications" [ngClass]="{'active': showNotifications}"
                cdkOverlayOrigin
                #overlayTrigger="cdkOverlayOrigin">
            <mat-icon [matBadge]="notifications.length > 99 ? '99+' : notifications.length" matBadgeColor="warn"
                      [matBadgeHidden]="!notifications.length" aria-hidden="false">
                notifications
            </mat-icon>
            <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="overlayTrigger"
                         [cdkConnectedOverlayOpen]="showNotifications"
                         (backdropClick)="showNotifications=false" cdkConnectedOverlayHasBackdrop="true"
                         cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop">
                <div id="notification-panel" class="mat-elevation-z8">
                    @if (!notifications.length) {
                        <div class="align-content-center p-2 no-notifications">
                            <mat-icon class="align-middle">notifications_none</mat-icon>
                            {{ hasPinged() ? 'No new notifications' : 'Loading notifications...' }}
                            <button style="margin-left: 24px;" color="primary" mat-flat-button
                                    (click)="viewAllNotifications()">
                                View All
                            </button>
                        </div>
                    } @else {
                        <div>
                            <div class="notification-button-header">
                            <span>
                                <mat-icon class="align-middle">notifications</mat-icon>
                                {{ notifications.length }} notification{{ notifications.length != 1 ? 's' : '' }}
                            </span>
                                <span>
                                <button color="primary" mat-flat-button (click)="markAllNotificationsRead()">
                                    Dismiss All
                                </button>
                                <button color="primary" mat-flat-button (click)="viewAllNotifications()">
                                    View All
                                </button>
                            </span>
                            </div>
                            <div class="notification-group">
                                <div *ngFor="let notification of displayedNotifications" class="notification-tile"
                                     [ngClass]="{'notification-linked': !!notification.target_path}"
                                     (click)="goToNotification(notification.id, notification.target_path)">
                                    <div class="notification-title-row">
                                    <span>
                                        <span class="time-chip"
                                              [matTooltip]="notification.getCreatedTime().format(dateFormat)"
                                              matTooltipPosition="left">
                                            {{ notification.ctime | dateChip }}</span>
                                        <span class="notification-title">{{ notification.title }}</span>
                                    </span>
                                        <span class="notification-options">
                                        <mat-icon matTooltip="Dismiss" matTooltipPosition="left"
                                                  (click)="markNotificationRead($event, notification.id)">
                                    close
                                        </mat-icon>
                                    </span>
                                    </div>
                                    <div class="d-flex">
                                    <span class="notification-content">
                                        {{ notification.content }}
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </ng-template>
        </button>
    }
</mat-toolbar>
