import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {SecurityBase} from '@app/models/security-base.model';

@Component({
    selector: 'devfu-document-security-table',
    templateUrl: './document-security-table.component.html',
    styleUrl: './document-security-table.component.scss'
})
export class DocumentSecurityTableComponent implements OnInit, OnChanges {
    @Input() securityEntries: SecurityBase[] = [];
    @Input() showReason: boolean = false;
    @Input() showActions: boolean = true;
    @Output() delete: EventEmitter<number> = new EventEmitter<number>();

    entryDataSource: MatTableDataSource<SecurityBase>;
    columns = ['group', 'user', 'role'];

    ngOnInit(): void {
        this.createEntries();
        this.updateColumns();
    }

    ngOnChanges(changes: SimpleChanges): void {
        let updateCols = false;
        for (const propName in changes) {
            if (changes.hasOwnProperty(propName)) {
                switch (propName) {
                    case 'securityEntries': {
                        this.createEntries();
                        break;
                    }
                    case 'showReason':
                    case 'showActions': {
                        updateCols = true;
                    }
                }
            }
        }

        // Ensure we only call it once
        if (updateCols) {
            this.updateColumns();
        }
    }

    createEntries(): void {
        this.entryDataSource = new MatTableDataSource<SecurityBase>(this.securityEntries.filter(s => s.user_name || s.group_name));
    }

    updateColumns() {
        this.columns = ['group', 'user', 'role'];

        if (this.showReason) {
            this.columns.push('reason');
        }

        if (this.showActions) {
            this.columns.push('actions');
        }
    }

    deleteEntry(id: number) {
        this.delete.emit(id);
    }

}
