import {User} from '@app/models';
import {AuthGroup} from '@app/models/auth.models';

export class UserGroupCombo {
    constructor(
        public user_id: number = 0,
        public user_name: string = '',
        public group_id: number = 0,
        public group_name: string = ''
    ) {
    }

    public static mapFromUser(u: User): UserGroupCombo {
        const ugc = new UserGroupCombo();
        ugc.user_id = u.id;
        ugc.user_name = u.name;

        return ugc;
    }

    public static mapFromGroup(g: AuthGroup): UserGroupCombo {
        const ugc = new UserGroupCombo();
        ugc.group_id = g.id;
        ugc.group_name = g.name;

        return ugc;
    }
}
